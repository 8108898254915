import React, { ReactElement, useState, useCallback, useEffect } from 'react'
import { TableWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { getDataBupot, getSyncBulkByid, updateBupot, deleteBupot } from './TableBuktiPotong.service'
import { getDataBupotSupport, getSyncBulkByidSupport, updateBupotSupport, deleteBupotSupport } from './TableBuktiPotongSupport.service'
import TableBuktiPotongForm from './TableBuktiPotongForm'
import TableBuktiPotongFormScanner from './TableBuktiPotongFormScanner'
import TableBuktiPotongFormExport from './TableBuktiPotongFormExport'
import TableBuktiPotongFormSync from './TableBuktiPotongFormSync'
import { Select } from '@react-md/form'
import { inject, observer } from 'mobx-react'
import TableBuktiPotongFormDokumen from './TableBuktiPotongFormDokumen'
import { DropdownMenu } from 'react-md'
import _uniqueId from "lodash/uniqueId";
import { toast } from '../../libs/react-mpk/services'
import { errorRequest } from '../../libs/react-mpk/services/toast.service'
import { useParams } from 'react-router'
import moment from 'moment'
import { inputTypes } from '../../libs/react-mpk/config/constant'


const TableBuktiPotong = ({
  className = '',
  showCommandbar = true,
  fgLapor = null,
  authStore,
  ...props
}) => {
  const options = authStore.user.company.subscriptions[0].organizations.map(d => ({
    label: d.npwp,
    value: d.npwp
  }))
  const { companyId } = useParams()
  const [showForm, setShowForm] = useState(false)
  const [showFormExport, setShowFormExport] = useState(false)
  const [showFormScanner, setShowFormScanner] = useState(false)
  const [showFormSync, setShowFormSync] = useState(false)
  const [titleScanner, setTitleScanner] = useState('Form File Scanner')
  const [showRef, setShowRef] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)
  const [selectedDokPemotongan, setSelectedDokPemotongan] = useState(null)
  const [npwp, setNpwp] = useState('')
  const [konfirmasi, setKonfirmasi] = useState([])
  const [id] = useState(_uniqueId('prefix-'));
  let isSupport = authStore.user.isSupport


  const onchange = (e) => {
    setNpwp(e)
  }


  return (
    <>
      <TableWrapper
        baseId="mod-table-bukti-potong"
        title={t.translate('modules.table.title')}
        className={className}
        hintIconClassName="mdi mdi-information"
        hintMore={t.translate('modules.table.hint.more')}
        extraFilter={
          <div>
            <Select
              id="adsad"
              label="NPWP"
              name="npwp"
              value={npwp}
              onChange={onchange}
              options={[{ label: 'All', value: '' }, ...options]}
            />
          </div>
        }

        defaultData={[]}
        defaultSortBy="createdDate"
        defaultCollapse={true}
        useQueryHistory={false}
        showFilterPeriod={true}
        useCriteria={true}
        multiColumnFilter={true}
        selectable={false}
        useFilterAdd={true}
        columns={[
          {
            label: 'Perlu Konfirmasi',
            sortable: true,
            searchable: false,
            key: '',
            render: (item, index) => {
              // switch (item.fgStatus) {
              //   case '0': return "Iya";
              //   case '1': return "Tidak";
              //   case '2': return "Sudah";
              //   default: return "Iya"
              // }
              return <Select
                onChange={async (value) => {
                  konfirmasi[item.index].konfirmasi = value
                  setKonfirmasi(konfirmasi)
                  item.konfirmasi = value
                  if (isSupport) {
                    await updateBupotSupport(item.idBupot, item)
                  } else {
                    await updateBupot(item.idBupot, item)
                  }
                }}
                value={konfirmasi && konfirmasi.length > 0 ? konfirmasi[item?.index]?.konfirmasi : 'Tidak'}
                id={item.idBupot}
                options={[
                  { label: "Iya", value: "Iya" },
                  { label: "Tidak", value: "Tidak" }
                ]}

              >
              </Select>
            }

          },
          {
            label: 'Status Sync',
            sortable: true,
            searchable: true,
            definition: {
              inputType: inputTypes.INPUT,
              criterias: [
                'contains', 'orContains', 'equals', 'orEquals', 'notEquals', 'orNotEquals', 'in', 'orIn'
              ]
            },
            key: 'sync',
            render: item => item.sync

          },

          {
            label: 'Fg Status',
            sortable: true,
            searchable: true,
            definition: {
              inputType: inputTypes.INPUT,
              criterias: [
                'contains', 'orContains', 'equals', 'orEquals', 'notEquals', 'orNotEquals', 'in', 'orIn'
              ]
            },
            key: 'fgStatus',
            render: item => item.fgStatus
          },
          {
            label: 'Fg Lapor',
            sortable: true,
            searchable: true,
            key: 'fgLapor',
            definition: {
              inputType: inputTypes.INPUT,
              criterias: [
                'contains', 'orContains', 'equals', 'orEquals', 'notEquals', 'orNotEquals', 'in', 'orIn'
              ]
            },
            render: item => (
              <TableWrapper.Status
                type={item.fgLapor === "Sudah Lapor"
                  ? TableWrapper.Status.types.SUCCESS
                  : TableWrapper.Status.types.ERROR
                }
              >
                {item.fgLapor === "Sudah Lapor" ? 'Sudah Lapor' : 'Belum Lapor'}
              </TableWrapper.Status>
            )
          },
          {
            label: 'Fg Fasilitas',
            searchable: true,
            sortable: true,
            key: 'fgFasilitas',
            definition: {
              inputType: inputTypes.INPUT,
              criterias: [
                'contains', 'orContains', 'equals', 'orEquals', 'notEquals', 'orNotEquals', 'in', 'orIn'
              ]
            },
            render: item => (
              item.fgFasilitas == null ? "-" : item.fgFasilitas
            )
          },
          {
            label: 'Nomor Bukti Potong',
            searchable: true,
            sortable: true,
            key: 'noBupot',
            definition: {
              inputType: inputTypes.INPUT,
              criterias: [
                'contains', 'orContains', 'equals', 'orEquals', 'notEquals', 'orNotEquals', 'in', 'orIn'
              ]
            },
            render: item => item.noBupot
          },
          {
            label: 'Nomor Revisi',
            searchable: true,
            sortable: true,
            definition: {
              inputType: inputTypes.INPUT,
              criterias: [
                'contains', 'orContains', 'equals', 'orEquals', 'notEquals', 'orNotEquals', 'in', 'orIn'
              ]
            },
            key: 'revNo',
            render: item => item.revNo
          },
          {
            label: 'Pasal',
            searchable: true,
            sortable: true,
            definition: {
              inputType: inputTypes.INPUT,
              criterias: [
                'contains', 'orContains', 'equals', 'orEquals', 'notEquals', 'orNotEquals', 'in', 'orIn'
              ]
            },
            key: 'jnsPasal',
            render: item => item.jnsPasal
          },
          {
            label: 'FOTF',
            searchable: true,
            sortable: true,
            definition: {
              inputType: inputTypes.INPUT,
              criterias: [
                'contains', 'orContains', 'equals', 'orEquals', 'notEquals', 'orNotEquals', 'in', 'orIn'
              ]
            },
            key: 'fotf',
            render: item => item.fotf
          },
          {
            label: 'Kode Objek Pajak',
            searchable: true,
            sortable: true,
            definition: {
              inputType: inputTypes.INPUT,
              criterias: [
                'contains', 'orContains', 'equals', 'orEquals', 'notEquals', 'orNotEquals', 'in', 'orIn'
              ]
            },
            key: 'kdObjPjk',
            render: item => item.kdObjPjk
          },
          {
            label: 'Jenis Penghasilan',
            searchable: true,
            sortable: true,
            definition: {
              inputType: inputTypes.INPUT,
              criterias: [
                'contains', 'orContains', 'equals', 'orEquals', 'notEquals', 'orNotEquals', 'in', 'orIn'
              ]
            },
            key: 'jnsPenghasilan',
            render: item => (
              item.jnsPenghasilan === "6" && item.jnsPasal === "23" ? item.jnsPenghasilan + " - imbalan/jasa lainnya" :
                item.jnsPenghasilan === "-" && item.jnsPasal === "15" ? item.jnsPenghasilan + "" :
                  item.jnsPenghasilan === "7" && item.jnsPasal === "22" ? item.jnsPenghasilan + " - Nilai Impor Bank Devisa/Ditjen Bea Cukai" :
                    item.jnsPenghasilan === "-" && item.jnsPasal === "22" ? item.jnsPenghasilan + "" :
                      item.jnsPenghasilan === "16" && item.jnsPasal === "22" ? item.jnsPenghasilan + " - Pembelian Barang oleh Badan Tertentu" :
                        item.jnsPenghasilan === "1" && item.jnsPasal === "22" ? item.jnsPenghasilan + " - Badan Usaha Industri Semen" :
                          item.jnsPenghasilan === "4" && item.jnsPasal === "22" ? item.jnsPenghasilan + " - Badan Usaha Industri Baja" :
                            item.jnsPenghasilan === "5" && item.jnsPasal === "22" ? item.jnsPenghasilan + " - Badan Usaha Industri Otomotif" :
                              item.jnsPenghasilan === "2" && item.jnsPasal === "22" ? item.jnsPenghasilan + " - Badan Usaha Industri Farmasi" :
                                item.jnsPenghasilan === "3" && item.jnsPasal === "22" ? item.jnsPenghasilan + " - Badan Usaha Industri Kertas" :
                                  item.jnsPenghasilan === "17" && item.jnsPasal === "22" ? item.jnsPenghasilan + " - Penjualan Kendaraan Bermotor DN" :
                                    item.jnsPenghasilan === "18" && item.jnsPasal === "22" ? item.jnsPenghasilan + " - Pembelian Minerba dan Mineral Bukan Logam dari Pemegang" :
                                      item.jnsPenghasilan === "14" && item.jnsPasal === "22" ? item.jnsPenghasilan + " - Penjualan Emas Batangan oleh Badan Usaha" :
                                        item.jnsPenghasilan === "10" && item.jnsPasal === "22" ? item.jnsPenghasilan + " - Pembelian Barang Keperluan Industri dalam sektor Perhutanan" :
                                          item.jnsPenghasilan === "11" && item.jnsPasal === "22" ? item.jnsPenghasilan + " - Pembelian Barang Keperluan Industri dalam sektor Perkebunan" :
                                            item.jnsPenghasilan === "12" && item.jnsPasal === "22" ? item.jnsPenghasilan + " - Pembelian Barang Keperluan Industri dalam sektor Pertanian " :
                                              item.jnsPenghasilan === "16" && item.jnsPasal === "22" ? item.jnsPenghasilan + " - Pembelian Barang oleh Badan Tertentu" :
                                                item.jnsPenghasilan === "13" && item.jnsPasal === "22" ? item.jnsPenghasilan + " - Pembelian Barang Keperluan Industri dalam sektor Perikanan" :
                                                  item.jnsPenghasilan === "9" && item.jnsPasal === "22" ? item.jnsPenghasilan + " - Penjualan BBM, BBG dan Pelumas" :
                                                    item.jnsPenghasilan === "1" && item.jnsPasal === "23" ? item.jnsPenghasilan + " - Deviden" :
                                                      item.jnsPenghasilan === "2" && item.jnsPasal === "23" ? item.jnsPenghasilan + " - Bunga" :
                                                        item.jnsPenghasilan === "3" && item.jnsPasal === "23" ? item.jnsPenghasilan + " - Royalti" :
                                                          item.jnsPenghasilan === "4" && item.jnsPasal === "23" ? item.jnsPenghasilan + " - Hadiah dan Penghargaan" :
                                                            item.jnsPenghasilan === "7" && item.jnsPasal === "23" ? item.jnsPenghasilan + " - Sewa dan Penghasilan Lain Sehubungan dengan penggunaan Harta" :
                                                              item.jnsPenghasilan === "8" && item.jnsPasal === "23" ? item.jnsPenghasilan + " - Pensiun dan Pembayaran Berkala Lainnya" :
                                                                item.jnsPenghasilan === "9" && item.jnsPasal === "23" ? item.jnsPenghasilan + " - Penghasilan dari Penjualan Harta di Indonesia" :
                                                                  item.jnsPenghasilan === "10" && item.jnsPasal === "23" ? item.jnsPenghasilan + " - Premi Asuransi Termasuk Premi Reasuransi" :
                                                                    item.jnsPenghasilan === "11" && item.jnsPasal === "23" ? item.jnsPenghasilan + " - PKP Sesudah dikurangi PPh Suatu BUT" :
                                                                      item.jnsPenghasilan + ""
            )
          },
          {
            label: 'Bruto',
            searchable: true,
            sortable: true,
            definition: {
              inputType: inputTypes.INPUT,
              criterias: [
                'contains', 'orContains', 'equals', 'orEquals', 'notEquals', 'orNotEquals', 'in', 'orIn'
              ]
            },
            key: 'jmlBruto',
            render: item => item.jmlBruto
          },
          {
            label: 'Tarif',
            searchable: false,
            sortable: true,
            definition: {
              inputType: inputTypes.INPUT,
              criterias: [
                'contains', 'orContains', 'equals', 'orEquals', 'notEquals', 'orNotEquals', 'in', 'orIn'
              ]
            },
            key: 'tarif',
            render: item => item.tarif
          },
          {
            label: 'PPH Dipotong',
            searchable: true,
            sortable: true,
            definition: {
              inputType: inputTypes.INPUT,
              criterias: [
                'contains', 'orContains', 'equals', 'orEquals', 'notEquals', 'orNotEquals', 'in', 'orIn'
              ]
            },
            key: 'pphDipotong',
            render: item => item.pphDipotong
          },
          {
            label: 'Tanggal Pemotongan',
            searchable: true,
            sortable: true,
            definition: {
              inputType: inputTypes.INPUT,
              criterias: [
                'contains', 'orContains', 'equals', 'orEquals', 'notEquals', 'orNotEquals', 'in', 'orIn'
              ]
            },
            key: 'tglPemotongan',
            render: item => item.tglPemotongan
          },
          {
            label: 'Masa Pajak',
            searchable: true,
            sortable: true,
            definition: {
              inputType: inputTypes.INPUT,
              criterias: [
                'contains', 'orContains', 'equals', 'orEquals', 'notEquals', 'orNotEquals', 'in', 'orIn'
              ]
            },
            key: 'masaPajak',
            render: item => item.masaPajak
          },
          {
            label: 'Tahun Pajak',
            searchable: true,
            sortable: true,
            definition: {
              inputType: inputTypes.INPUT,
              criterias: [
                'contains', 'orContains', 'equals', 'orEquals', 'notEquals', 'orNotEquals', 'in', 'orIn'
              ]
            },
            key: 'thnPajak',
            render: item => item.thnPajak
          },
          {
            label: 'Identitas Dipotong',
            searchable: false,
            sortable: true,
            definition: {
              inputType: inputTypes.INPUT,
              criterias: [
                'contains', 'orContains', 'equals', 'orEquals', 'notEquals', 'orNotEquals', 'in', 'orIn'
              ]
            },
            key: 'identitasDipotong',
            render: item => item.identitasDipotong
          },
          {
            label: 'Nomor Dokumen Dasar Pemotongan',
            searchable: false,
            sortable: false,
            // definition: {
            //   inputType: inputTypes.INPUT,
            //   criterias: [
            //     'contains', 'orContains', 'equals', 'orEquals', 'notEquals', 'orNotEquals', 'in', 'orIn'
            //   ]
            // },
            key: 'dokDasarPemotongan',
            render: item => (
              <div>
                {item.dokDasarPemotongan.map(b => (
                  <div key={b.nomerDok} className='mpk-link' onClick={() => {
                    setSelectedDokPemotongan(b)
                  }}>
                    {b.nomorDok}
                  </div>
                ))}
              </div>
            )
          },
          {
            label: 'Nama Pemotong',
            searchable: true,
            sortable: true,
            definition: {
              inputType: inputTypes.INPUT,
              criterias: [
                'contains', 'orContains', 'equals', 'orEquals', 'notEquals', 'orNotEquals', 'in', 'orIn'
              ]
            },
            key: 'identitasPemotongJnsNamaPemotong',
            render: item => (
              <div>{item.identitasPemotong.namaPemotong}</div>
            )
          },
          {
            label: 'Alamat Pemotong',
            searchable: true,
            sortable: true,
            definition: {
              inputType: inputTypes.INPUT,
              criterias: [
                'contains', 'orContains', 'equals', 'orEquals', 'notEquals', 'orNotEquals', 'in', 'orIn'
              ]
            },
            key: 'identitasPemotonganAlamatPemotong',
            render: item => (
              <div>{item.identitasPemotong.alamatPemotong}</div>
            )
          },
          {
            label: 'NPWP Pemotong',
            searchable: true,
            sortable: true,
            definition: {
              inputType: inputTypes.INPUT,
              criterias: [
                'contains', 'orContains', 'equals', 'orEquals', 'notEquals', 'orNotEquals', 'in', 'orIn'
              ]
            },
            key: 'identitasPemotongNpwpPemotong',
            render: item => (
              <div>{item.identitasPemotong.npwpPemotong}</div>
            )
          },
          {
            label: 'URL Barcode',
            searchable: true,
            sortable: true,
            definition: {
              inputType: inputTypes.INPUT,
              criterias: [
                'contains', 'orContains', 'equals', 'orEquals', 'notEquals', 'orNotEquals', 'in', 'orIn'
              ]
            },
            key: 'decodedUrl',
            render: item => (
              <div>{item.decodedUrl}</div>
            )
          },
          {
            label: 'Tanggal Submit',
            sortable: true,
            definition: {
              inputType: inputTypes.DATEPICKER,
              criterias: ['greaterThan', 'lessThan']
            },
            searchable: true,
            key: 'createdDate',
            render: item => moment(item.createdDate).format('lll')
          },
          {
            label: 'Tanggal Update',
            definition: {
              inputType: inputTypes.DATEPICKER,
              criterias: ['greaterThan', 'lessThan']
            },
            sortable: true,
            searchable: true,
            key: 'lastUpdateDate',
            render: item => moment(item.lastModifiedDate).format('lll')

          },
          {
            label: 'Created By',
            searchable: true,
            sortable: true,
            definition: {
              inputType: inputTypes.INPUT,
              criterias: [
                'contains', 'orContains', 'equals', 'orEquals', 'notEquals', 'orNotEquals', 'in', 'orIn'
              ]
            },
            key: 'createdBy',
            render: item => (
              <div>{item.createdBy}</div>
            )
          }
        ]}
        actions={[
          new TableWrapper.action(
            'Add',
            'mdi mdi-file-pdf',
            () => {
              setTitleScanner('Form File Scanner')
              setSelectedItem(null)
              setShowForm(true)

            },
            true
          ),
          new TableWrapper.action(
            'Add Marge',
            'mdi mdi-file-document-multiple',
            () => {
              setTitleScanner('Form File Scanner Merge')
              setSelectedItem(null)
              setShowForm(true)
            },
            true
          ),
          new TableWrapper.action(
            'Scanner',
            'mdi mdi-scanner',
            () => {
              setSelectedItem(null)
              setShowFormScanner(true)
            },
            true
          ),
          new TableWrapper.action(
            'Export',
            'mdi mdi-export',
            () => {
              setSelectedItem(null)
              setShowFormExport(true)
            },
            true
          ),
          new TableWrapper.action(
            'Sinkronisasi',
            'mdi mdi-cloud-sync',
            () => {
              setSelectedItem(null)
              setShowFormSync(true)
            },
            true
          )
        ]}
        itemActions={[
          new TableWrapper.action(
            'Sikronisasi By Id',
            'mdi mdi-refresh',
            (item) => {
              props.modalStore.showConfirm({
                title: "Sync",
                children: "Apakah anda ingin mensync data ini ?",
                onSubmit: async (callback) => {
                  try {
                    if (isSupport) {
                      await getSyncBulkByidSupport(item.idBupot)
                    } else {
                      await getSyncBulkByid(item.idBupot)
                    }
                    TableWrapper.reload("mod-table-bukti-potong")
                    toast.success("data berhasil di sync")
                    callback()
                  } catch (error) {
                    toast.errorRequest(error)
                    callback()
                  }
                }
              })
            },
            true
          ),
          new TableWrapper.action(
            "Delete",
            "mdi mdi-delete",
            (item) => {
              props.modalStore.showConfirm({
                title: "Delete",
                children: "Apakah Anda yakin ingin menghapus Bukti Potong ini ?",
                onSubmit: async (callback) => {
                  try {
                    if (isSupport) {
                      await deleteBupotSupport(item.idBupot)
                    } else {
                      await deleteBupot(item.idBupot);
                    }
                    TableWrapper.reload("mod-table-bukti-potong");
                    callback();
                    toast.success("Bukti Potong berhasil di hapus");
                  } catch (error) {
                    toast.errorRequest(error);
                    callback();
                  }
                },
              });
            },
            true,
          ),
          // new TableWrapper.action(
          //   'Referensi',
          //   'mdi mdi-plus',
          //   (item) => {
          //     setSelectedItem(item)
          //     setShowRef(true)
          //   },
          //   true
          // )
        ]}
        onFetchData={async query => {
          console.log("🚀 ~ file: TableBuktiPotong.js ~ line 594 ~ query['columnSelect.equals']", query['columnSelect.equals'])
          if (npwp) {
            query["identitasDipotong.equals"] = npwp
          }
          if (query['lastUpdateDate.greaterThan']) {
            query['lastUpdateDate.greaterThan'] = `${query['lastUpdateDate.greaterThan']}T00:00:00.Z`
          }
          if (query['lastUpdateDate.lessThan']) {
            query['lastUpdateDate.lessThan'] = `${query['lastUpdateDate.lessThan']}T23:59:59.Z`
          }
          if (query['createdDate.greaterThan']) {
            query['createdDate.greaterThan'] = `${query['createdDate.greaterThan']}T00:00:00.Z`
          }
          if (query['createdDate.lessThan']) {
            query['createdDate.lessThan'] = `${query['createdDate.lessThan']}T23:59:59.Z`
          }
          // query['id.orEquals'] = '3d4697fd-ffdf-4a52-b252-ac5539059c6e'
          // query['fotf.orEquals'] = 'Tidak Final'
          if (isSupport) {
            var data = await getDataBupotSupport(query, fgLapor)
          } else {
            var data = await getDataBupot(query, fgLapor)
          }
          var realData = data.data
          realData.map((d, index) => {
            d.index = index
            // d.konfirmasi = d.fgStatus === '0' ? "Tidak" : 'Iya'
          })
          setKonfirmasi(realData)
          return data
        }}
        showCommandbar={showCommandbar}

      />
      <TableBuktiPotongForm
        visible={showForm}
        title={titleScanner}
        onRequestClose={() => {
          setShowForm(false)
          setSelectedItem(null)
        }}
        item={selectedItem}
      />
      <TableBuktiPotongFormExport
        visible={showFormExport}
        onRequestClose={() => {
          setShowFormExport(false)
          setSelectedItem(null)
        }}
        item={selectedItem}
      />

      <TableBuktiPotongFormSync
        visible={showFormSync}
        onRequestClose={() => {
          setShowFormSync(false)
          setSelectedItem(null)
        }}
        item={selectedItem}
      />
      <TableBuktiPotongFormDokumen
        visible={selectedDokPemotongan ? true : false}
        onRequestClose={() => {
          setSelectedDokPemotongan(null)
        }}
        item={selectedDokPemotongan}
      />

      <TableBuktiPotongFormScanner
        visible={showFormScanner}
        onRequestClose={() => {
          setShowFormScanner(false)
          setSelectedItem(null)
        }}
        item={selectedItem}
      />
    </>
  )
}

export default inject('authStore', 'modalStore')(observer(TableBuktiPotong))