import React, {useState, useEffect} from 'react'
import { Router, Switch, Route } from "react-router-dom"
import { sso } from '../../libs/react-mpk/hocs'
import { PageWrapper } from '../../libs/react-mpk/wrapper'
import Companies from '../../modules/Companies'
import { inject, observer } from "mobx-react";
import {http, toast} from '../../libs/react-mpk/services'
import {LogoutService, GetSN} from '../../modules/Login/Login.service'
import {getDataUserById} from '../../modules/OnPremise/TabelUser/TabelUser.service'
import {getDataUserByIdUser} from '../../modules/OnPremise/Profile/TabelUser.service'
import { Notification } from '../../libs/react-mpk/components';
import {downloadReport} from '../../modules/OnPremise/TableBuktiPotong/TableBuktiPotong.service'
import notificationService, {updateNotificationBulk} from '../../modules/OnPremise/BuktiPotong/Notification.service'
import qs from 'query-string';
import t from 'counterpart'
import moment from 'moment'


import {BuktiPotong,
  TableLogImport,
  TableLogScan, 
  TabelRoles,
  TabelUser,
  TabelProxy,
  TabelSftp,
  TabelSceduler,
  Profile,
  TabelNpwp,
  UserNpwp,
  EmailSetting
} from '../../modules/OnPremise'
import { LoaderInfo } from '../../libs/react-mpk/components'


const requestOptions = {
  useDefaultHost: false,
  useDefaultBaseUrl: false,
  useDefaultHeader: true,
}

const Index = ({history, authStore, navigationStore, envStore, temporaryStore, modalStore}) => {
  let [loading, setLoading] = useState(true)
  // let [firstload, setFirstLoad] = useState(true)
  
  useEffect(async ()=>{
    http.setRequestInterceptor((config, url) => {
      return new Promise(async (resolve, reject) => {
        config.headers = config.headers || {};
        config.withCredentials = true;
        resolve(config)
      })
    });
    
    await setErrorHandler();
    await getProfile();
    setLoading(false)
  },[])

  const getProfile = async () => {
    let url = '/api/sso/op'
    let basePath = '/general/index'
    try {
      let res = await ( http.get(url, {}, {}, requestOptions));
      let DataSn = await GetSN()
      envStore.setEnv({
        additionAppInfo : [
          {
            title: 'Serial Number',
            value: DataSn.data.sn,
          }
        ]
      })
      let resMe
      if(res.data.role === 'ROLE_USER'){
        resMe = await getDataUserByIdUser(res.data.id)
      }else{
        resMe = await getDataUserById(res.data.id)
      }
      let dataUser = {...res.data, nama: resMe.data.nama, name : resMe.data.nama, email: resMe.data.email, role: resMe.data.role}

      authStore.setProfile(dataUser);
      if(http.baseUrl === ''){
        const { application, product } = res.data;
        if(application || product){
          const baseUrl = product ? product.baseUrl : (
            application ? application.baseUrl : null
          )
          http.setBaseUrl(baseUrl)
        }
      }

      let { menu } = res.data;
      if(menu){
        let defaultMenu;
        if(basePath === window.location.pathname){
          for(let item of menu){
            if(item.children){
              defaultMenu = item.children[0];
              break;
            }
          }
          setTimeout(() => {
            let targetPath = defaultMenu ? defaultMenu.path : basePath
            temporaryStore.setProperties('defaultPath', targetPath)
            navigationStore.redirectTo(targetPath)
          }, 1000);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  const setErrorHandler = () => {
    return new Promise((resolve, reject)=> {
      http.setErrorHanlder((err) => {
        if(err.response && err.response.data){
          const { data } = err.response;
          if(data.status === 401){
            toast.errorRequest(data.title+', '+data.detail)
            navigationStore.redirectTo('/general/login')
            reject()
          }else{
            toast.errorRequest('Something ERROR!!!')
          }
        }else{
          toast.errorRequest('Something ERROR!!!')
        }
        return err;
        // return false
      })
      resolve();
    })
  }

  let [nStompClient, setNStompClient] = useState(null)
	let [notifCount, setNotifCount] = useState(0)
	const initWs = () => {
		let accessToken = authStore.getAccessToken()
		let accessKey = authStore.getAccessKey()
		let { host, baseUrl } = envStore.env['apiGateway']
    let params = {}
		// if (accessKey) params.access_key = accessKey;
		// else 
    // if (accessToken){
      // params.access_token = accessToken
      // params.access_token = 'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJzYXRyaWFwcmliYWRpIiwiYmVhcmVyIjp7ImlkIjoiMDAwMDAwMDAtNTkzOC0yMWViLWIyMDEtZmI5Zjc0NjNlMzM0IiwibmFtYSI6IlNhdHJpYSBQcmliYWRpIiwiZW1haWwiOiJzYXRyaWEucHJpYmFkaTE5QGdtYWlsLmNvbSIsInVzZXJuYW1lIjoic2F0cmlhcHJpYmFkaSIsInBhc3N3b3JkIjpudWxsLCJyb2xlIjoiUk9MRV9BRE1JTiIsImlzQWN0aXZlIjp0cnVlfSwiZXhwIjoxNjQ4Njk4MDI2fQ.uVwjTIH1waz1wMW9NxOuXGwH25eceq3rHjwwuEADPxw'
    // } 
    let nSocket = window.SockJS(`${host}${baseUrl}/api/ws?${qs.stringify({channel: 'notification', ...params})}`)


		nStompClient = window.Stomp.over(nSocket)
		nStompClient.connect({}, onNWsConnected, onNWsError)
	}


	const onNWsConnected = () => {
		nStompClient.subscribe('/users/queue/notification', onNMessageReceived);
		setNStompClient(nStompClient)
	}

	const onNWsError = (error) => {
		console.log('WS Notification Error')
		console.log(error)
		toast.error(error)
		setTimeout(() => {
			console.log('WS Notification Reconnecting...')
  		nStompClient.connect({}, onNWsConnected, onNWsError)
			setNStompClient(nStompClient)
		}, 1000)
	}

	const onNMessageReceived = (payload) => {
		let message = JSON.parse(payload.body)
		setNotifCount(count => (count+1))
	}



  useEffect(() => {
    if(!loading){
      initWs()
    }
	}, [loading])

  return loading ? <LoaderInfo/>:(
    <PageWrapper
      handleLogout ={() => {
          modalStore.showConfirm({
            title: t.translate('mpk.column.logout'),
            children: t.translate('mpk.sentence.logoutMessage'),
            onSubmit: async (callback) => {
              try {
                let res = await LogoutService({token:''})
                if (res) navigationStore.redirectTo('/general/login')
                callback()
              } catch (error) {
                console.log("🚀 ~ file: Index.routes.js ~ line 113 ~ onSubmit: ~ error", error)
              }
            }
          })
      }}
      handleProfile = {()=>{
        navigationStore.redirectTo('/general/index/profil')
      }}
      appbar={{
        rightCorner: (
          <Notification
            count={notifCount}
            onOpen={async (data) => {
              // for(let item of data){
              //   if(!item.isRead){
              //     await notificationService.put(item.id, {...item, isRead: true})
              //   }
              // }
              try {
                await updateNotificationBulk({'isRead.equals': false}) 
                setNotifCount(0)
              } catch (error) {
                toast.errorRequest(error)
              }
            }}
            onFetchData={(query) => {
              return new Promise(async (resolve, reject) => {
                try {
                  let res = await notificationService.get({...query, sort:'createdDate,DESC'})
                  let unread = res.data.filter(d => !d.isRead).length
                  setNotifCount(unread)
                  resolve(res)
                } catch (error) {
                  reject(error)
                }
              })
            }}
            render={item => (
              <>
                {/* {item.notification.toLowerCase() === 'export' ? ( */}
                  <div 
                    className="mpk-link"
                    onClick={async () => {
                      try {
                          await downloadReport(item.data)	
                      } catch (error) {
                        console.log("🚀 ~ file: Company.routes.js ~ line 121 ~ onClick={ ~ error", error)
                        toast.errorRequest(error)
                      }
                    }}
                  >
                    {item.data}
                  </div>
                {/* ) : (
                  <div>{item.data}</div>
                )} */}
                <div className="mpk-font size-S color-D3">{moment(item.createdDate).format('lll')}</div>
              </>
            )}
          />
        )
      }}
    >
      <Router history={history}>
        <Switch>
          <Route
            path='/general/index/details/:tab'
            render={props => (
              <BuktiPotong {...props}/>
            )}
          />
          <Route
            path='/general/index/logExport'
            render={props => (
              <TableLogImport {...props}/>
            )}
          />
          <Route
            path='/general/index/logScan'
            render={props => (
              <TableLogScan {...props}/>
            )}
          />
          <Route
            path='/general/index/role'
            render={props => (
              <TabelRoles {...props}/>
            )}
          />
          <Route
            path='/general/index/user'
            render={props => (
              <TabelUser {...props}/>
            )}
          />
          <Route
            path='/general/index/user_npwp/:userId'
            render={props => (
              <UserNpwp {...props}/>
            )}
          />
          <Route
            path='/general/index/npwp'
            render={props => (
              <TabelNpwp {...props}/>
            )}
          />
          <Route
            path='/general/index/profil'
            render={props => (
              <Profile {...props}/>
            )}
          />
          <Route
            path='/general/index/proxy'
            render={props => (
              <TabelProxy {...props}/>
            )}
          />
          <Route
            path='/general/index/sftp'
            render={props => (
              <TabelSftp {...props}/>
            )}
          />
          <Route
            path='/general/index/sceduler'
            render={props => (
              <TabelSceduler {...props}/>
            )}
          />
          <Route
            path='/general/index/email'
            render={props => (
              <EmailSetting {...props}/>
            )}
          />
        </Switch>
      </Router>
    </PageWrapper>
  )
}

export default inject("authStore", "envStore", "navigationStore", "temporaryStore", "modalStore")(observer(Index))