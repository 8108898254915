import React, { useState, useEffect } from "react";
import { DataForm } from "../../../libs/react-mpk/components";
import moment from "moment";
import { inject, observer } from "mobx-react";
import { getFormatReport, getDataBupot, DownloadPdfBupot } from "./TableBuktiPotong.service";
import { getDataSftp, deleteDataSftp } from "../TabelSftp/TabelSftp.service";
import { getDataSftpUser, deleteDataSftpUser } from "../TabelSftp/TabelSftpUser.service";
import { TableWrapper } from "../../../libs/react-mpk/wrapper";
import { toast } from "../../../libs/react-mpk/services";
import _ from "lodash";

const TableBuktiPotongFormExport = ({
  visible = false,
  fgLapor = null,
  onRequestClose = () => {},
  authStore,
  envStore,
  ...props
}) => {
  let [item, setItem] = useState(null);
  let [ready, setReady] = useState(true);
  let [disfotf, setDisFotf] = useState(true);
  let [exportPdf, setExportPdf] = useState(false);
  let [distipeFile, setDisTypeFile] = useState(false);
  let [defaultData, setDefaultData] = useState({
    tipeFile: "excel",
    tipeFilePdf: "pdf",
    tipeModel: "model1",
    thnPajak: "",
    fgStatus: "-",
    fgLapor: "-",
    fotf: "Tidak Final",
    identitasDipotong: '',
    useSftp: '',
    masaPajak: '',
    namaPemotong: '',
    npwpPemotong: '',
    dariNoBupot: '',
    sampaiNoBupot: '',
    dariTanggal: '',
    sampaiTanggal: '',
  })
  
  let [dataPemotong, setDataPemotong] = useState([]);
  let [dataDipotong, setDataDipotong] = useState([]);

  const [years, setYears] = useState([]);
  const [sftp, setSftp] = useState([]);
	let ROLE = authStore.user.role
  const generateYears = () => {
    let currentYear = moment(envStore.env.timestamp).year();
    for (let y = currentYear; y >= 2019; y--) {
      years.push(String(y));
    }
    setYears(years);
  };

  useEffect(()=>{
    setExportPdf(false)
    setDefaultData({
      tipeFile: "excel",
      tipeFilePdf: "pdf",
      tipeModel: "model1",
      thnPajak: "",
      fgStatus: "-",
      fgLapor: "-",
      fotf: "Tidak Final",
      identitasDipotong: '',
      useSftp: '',
      masaPajak: '',
      namaPemotong: '',
      npwpPemotong: '',
      dariNoBupot: '',
      sampaiNoBupot: '',
      dariTanggal: '',
      sampaiTanggal: '',
      
    })
  },[visible])

  useEffect( async ()=>{
    try {
      let data = await getDataBupot({sort: "createdDate,DESC", size : 20, page: 0}, fgLapor)
      let datasPemotong = [];
      let datasDipotong = [];
      if(data.data.length > 0){
        datasPemotong =  _.uniqBy(data.data, function (e) {
          return e.identitasPemotong.npwpPemotong;
        })
        datasDipotong =  _.uniqBy(data.data, function (e) {
          return e.identitasDipotong;
        })
      }

      setDataPemotong(datasPemotong)
      setDataDipotong(datasDipotong)
    } catch (error) {
      console.log("🚀 ~ file: TableBuktiPotongFormExport.js ~ line 69 ~ useEffect ~ error", error)    
    }
  },[])

  const getByNamePemotong = async (keyword, key) =>{
    try {
      let query = {sort: "createdDate,DESC", size : 10,page: 0}
      switch (key) {
        case 'namaPemotong':
          query['identitasPemotongjnsNamaPemotong.contains'] = keyword
          if(defaultData.npwpPemotong){
            query['identitasPemotongNpwpPemotong.contains'] = defaultData.npwpPemotong
          }
          break;
        case 'npwpPemotong':
          query['identitasPemotongNpwpPemotong.contains'] = keyword
          if(defaultData.namaPemotong){
            query['identitasPemotongjnsNamaPemotong.contains'] = defaultData.namaPemotong
          }
          break;
        default:
          break;
      }
      let data = await getDataBupot(query, fgLapor)
      let datasPemotong = [];
      if(data.data.length > 0){
        datasPemotong =  _.uniqBy(data.data, function (e) {
          return e.identitasPemotong.npwpPemotong;
        })
      }

      setDataPemotong(datasPemotong)
    } catch (error) {
      console.log("🚀 ~ file: TableBuktiPotongFormExport.js ~ line 69 ~ useEffect ~ error", error)    
    }
  }
  const getByDipotong = async (keyword, key) =>{
    try {
      let query = {sort: "createdDate,DESC", size : 10,page: 0}
      switch (key) {
        case 'identitasDipotong':
          query['identitasDipotong.contains'] = keyword
          if(defaultData.npwpPemotong){
            query['identitasPemotongNpwpPemotong.contains'] = defaultData.npwpPemotong
          }
          if(defaultData.namaPemotong){
            query['identitasPemotongjnsNamaPemotong.contains'] = defaultData.namaPemotong
          }
          break;
        default:
          break;
      }
      let data = await getDataBupot(query, fgLapor)
      let datasDipotong = [];
      if(data.data.length > 0){
        datasDipotong =  _.uniqBy(data.data, function (e) {
          return e.identitasDipotong;
        })
      }

      setDataDipotong(datasDipotong)
    } catch (error) {
      console.log("🚀 ~ file: TableBuktiPotongFormExport.js ~ line 69 ~ useEffect ~ error", error)    
    }
  }

  useEffect( async () => {
    generateYears();
    try {
      let datasftp
      if (ROLE === 'ROLE_ADMIN'){
        datasftp = await getDataSftp({ page: 0, size: 50 });
      }else{
        datasftp = await getDataSftpUser({ page: 0, size: 50 });
      }
      if(datasftp && datasftp.data){
        setSftp(datasftp.data);
      }
    } catch (error) {
      
    }
  }, []);

  return (
    ready && (
      <DataForm
        baseId="table-export-form"
        title="Form Export"
        visible={visible}
        // data={item}
        onRequestClose={onRequestClose}
        asDialog={true}
        defaultData={defaultData}
        definitions={[
          {
            inputType: DataForm.inputTypes.CHECKBOX,
            label: 'Menggunakan Setting SFTP',
            key: "useSftp"
          },
          {
            inputType: DataForm.inputTypes.SELECT,
            label: "Tipe Model",
            key: "tipeModel",
            value: defaultData.tipeModel,
            options: [
              { label: "Data Espt 1771", value: "model1" },
              { label: "Summary", value: "model2" },
              { label: "Data E-Form 1771", value: "model3" },
              { label: "Bukti Potong/Pungut", value: "model4" },
            ],
          },
          {
            inputType: DataForm.inputTypes.SELECT,
            label: "Tipe File",
            key: "tipeFile",
            disabled: distipeFile,
            show: !exportPdf,
            value: defaultData.tipeFile,
            options: [
              { label: "Excel", value: "excel" },
              { label: "CSV", value: "csv" },
            ],
          },
          {
            inputType: DataForm.inputTypes.SELECT,
            label: "Tipe File",
            key: "tipeFilePdf",
            show: exportPdf,
            disabled: exportPdf,
            value: defaultData.tipeFilePdf,
            options: [
              { label: "PDF", value: "pdf" },
            ],
          },
          {
            inputType: DataForm.inputTypes.SELECT,
            label: "Masa",
            key: "masaPajak",
            show: exportPdf,
            width: '50%',
            value: defaultData.masaPajak,
            required: true,
            className: 'mpk-padding-N padding-right',
            options: [
              {value: 1, label: 'Januari'},
              {value: 2, label: 'Februari'},
              {value: 3, label: 'Maret'},
              {value: 4, label: 'April'},
              {value: 5, label: 'Mei'},
              {value: 6, label: 'Juni'},
              {value: 7, label: 'Juli'},
              {value: 8, label: 'Agustus'},
              {value: 9, label: 'September'},
              {value: 10, label: 'Oktober'},
              {value: 11, label: 'November'},
              {value: 12, label: 'Desember'},
            ],
          },
          {
            inputType: DataForm.inputTypes.SELECT,
            label: "Tahun",
            key: "thnPajakPdf",
            show: exportPdf,
            width: '50%',
            required: true,
            value: defaultData.thnPajakPdf,
            options: years,
          },
          {
            inputType: DataForm.inputTypes.SELECT,
            label: "By Tahun Pajak",
            key: "thnPajak",
            show: !exportPdf,
            value: defaultData.thnPajak,
            options: years,
          },
          {
            inputType: DataForm.inputTypes.AUTOCOMPLETE,
            label: "Nama Pemotong",
            key: "namaPemotong",
            show: exportPdf,
            className: 'mpk-padding-N padding-right',
            width: '50%',
            value: defaultData.namaPemotong,
            data: dataPemotong.map(d => ({
                children: d.identitasPemotong.namaPemotong,
                value: d.identitasPemotong.namaPemotong
            })),
          },
          {
            inputType: DataForm.inputTypes.AUTOCOMPLETE,
            label: "No Identitas Pemotong",
            key: "npwpPemotong",
            show: exportPdf,
            width: '50%',
            // disabled: true,
            // readOnly: true,
            value: defaultData.npwpPemotong,
            data: dataPemotong.map(d => ({
              children: d.identitasPemotong.npwpPemotong,
              value: d.identitasPemotong.npwpPemotong
            })),
          },
          {
            inputType: DataForm.inputTypes.INPUT_MASK_NUMBER,
            label: "Dari No Bupot",
            key: "dariNoBupot",
            show: exportPdf,
            className: 'mpk-padding-N padding-right',
            width: '50%',
            minLength: 10,
            maxLength: 10,
          },
          {
            inputType: DataForm.inputTypes.INPUT_MASK_NUMBER,
            label: "Sampai No Bupot",
            key: "sampaiNoBupot",
            show: exportPdf,
            width: '50%',
            minLength: 10,
            maxLength: 10,
          },
          {
            inputType: DataForm.inputTypes.DATEPICKER,
            label: "Dari Tanggal",
            key: "dariTanggal",
            show: exportPdf,
            className: 'mpk-padding-N padding-right',
            width: '50%',
          },
          {
            inputType: DataForm.inputTypes.DATEPICKER,
            label: "Sampai Tanggal",
            key: "sampaiTanggal",
            show: exportPdf,
            className: 'mpk-padding-N padding-right',
            width: '50%',
          },
          {
            inputType: DataForm.inputTypes.SELECT,
            label: "Fg Status",
            show: !exportPdf,
            key: "fgStatus",
            value: defaultData.fgStatus,
            options: [
              { label: "Semua", value: "-" },
              { label: "Normal", value: "Normal" },
              { label: "Pembetulan", value: "Pembetulan" },
              { label: "Pembatalan", value: "Pembatalan" },
              { label: "Hapus", value: "Hapus" },
            ],
          },
          {
            inputType: DataForm.inputTypes.SELECT,
            label: "Fg Lapor",
            show: !exportPdf,
            value: defaultData.fgLapor,
            key: "fgLapor",
            options: [
              { label: "Semua", value: "-" },
              { label: "Sudah Lapor", value: "Sudah Lapor" },
              { label: "Belum Lapor", value: "Belum Lapor" },
            ],
          },
          {
            inputType: DataForm.inputTypes.SELECT,
            label: "FOTF",
            key: "fotf",
            show: !exportPdf,
            disabled: disfotf,
            value: defaultData.fotf,
            options: [
              { label: "Semua", value: "-" },
              { label: "Final", value: "Final" },
              { label: "Tidak Final", value: "Tidak Final" },
            ],
          },
          {
            inputType: DataForm.inputTypes.AUTOCOMPLETE,
            label: "Identitas Dipotong",
            // show: exportPdf,
            key: "identitasDipotong",
            value: defaultData.identitasDipotong,
            data: dataDipotong.map(d => ({
              children: d.identitasDipotong,
              value: d.identitasDipotong
            })), 
          },
        ]}
        onBeforeChange={(key,value)=>{
          if(key === 'namaPemotong'){
              getByNamePemotong(value, key)
          }
          if(key === 'npwpPemotong'){
              getByNamePemotong(value, key)
          }
          if(key === 'identitasDipotong'){
            getByDipotong(value, key)
            }
          return value
        }}
        onChange={(data, key, value)=>{
          
          if(key === 'tipeModel'){
            data = {
              tipeFile: "excel",
              tipeFilePdf: "pdf",
              tipeModel: value,
              thnPajak: "2019",
              fgStatus: "-",
              fgLapor: "-",
              fotf: "Tidak Final",
              identitasDipotong: '',
              useSftp: '',
              masaPajak: '',
              namaPemotong: '',
              npwpPemotong: '',
              dariNoBupot: '',
              sampaiNoBupot: '',
              dariTanggal: '',
              sampaiTanggal: '',
            }
            switch (value) {
              case 'model1':
                setDisFotf(true)
                setDisTypeFile(false)
                setExportPdf(false)
                data.fotf = 'Tidak Final'
                break;
              case 'model2':
                setDisFotf(false)
                setDisTypeFile(true)
                setExportPdf(false)
                data.fotf = '-'
                data.tipeFile = 'excel'
                break;
              case 'model3':
                setDisFotf(true)
                setDisTypeFile(false)
                setExportPdf(false)
                data.fotf = 'Tidak Final'
                break;
              case 'model4':
                setExportPdf(true)
                setDisFotf(true)
                setDisTypeFile(false)
                data.fotf = 'Tidak Final'
                break;
              default:
                setDisFotf(true)
                setExportPdf(false)
                setDisTypeFile(false)
                data.fotf = '-'
                break;
              }
          }
          setDefaultData(data)
          return data;
        }}
        onSubmit={async (data, callback) => {
          try {
            if(data.useSftp){
              if(sftp.length < 1){
                throw 'Setting SFTP harus di tambahkan terlebih dahulu!'
              }
            }else{
              if(sftp.length > 0){
              // if (ROLE === 'ROLE_ADMIN'){
              //   await deleteDataSftp(sftp[0].id)
              // }else{
              //   await deleteDataSftpUser(sftp[0].id)
              // }
              }
            }
            // delete data.useSftp
            if(data.tipeModel === 'model4'){
              let querypdf = {
                'isSaveFile.equals': true,
              }
              if(data.masaPajak){
                querypdf['masaPajak.equals'] = data.masaPajak
              }
              if(data.thnPajakPdf){
                querypdf['thnPajak.equals'] = data.thnPajakPdf
              }
              if(data.namaPemotong){
                querypdf['identitasPemotongjnsNamaPemotong.equals'] = data.namaPemotong
              }
              if(data.sampaiTanggal){
                querypdf['tglPemotonganAkhir.lessOrEqualThan'] = data.sampaiTanggal
              }
              if(data.dariTanggal){
                querypdf['tglPemotonganAwal.greaterOrEqualThan'] = data.dariTanggal
              }
              if(data.dariNoBupot){
                querypdf['noBupotAwal.greaterOrEqualThan'] = data.dariNoBupot
              }
              if(data.sampaiNoBupot){
                querypdf['noBupotAkhir.lessOrEqualThan'] = data.sampaiNoBupot
              }
              if(data.identitasDipotong){
                querypdf['identitasDipotong.equals'] = data.identitasDipotong
              }
              await DownloadPdfBupot(querypdf)
            }else{
              if (
                authStore.user.role === "ADMIN COMPANY" ||
                authStore.user.role === "SUB ADMIN COMPANY"
              ) {
                let query = {
                  "thnPajak.equals": data.thnPajak,
                  "identitasDipotong.equals": data.identitasDipotong,
                  "flagFasilitas.notEquals": "DTP",
                  // "companyId.equals": authStore.user.company.id.toString()
                };
                if (data.tipeModel === "model2") {
                  delete query["flagFasilitas.notEquals"];
                }
                if (data.fgStatus === "-") {
                  delete data.fgStatus;
                } else {
                  query["fgStatus.equals"] = data.fgStatus;
                }
                if (data.fgLapor === "-") {
                  delete data.fgLapor;
                } else {
                  query["fgLapor.equals"] = data.fgLapor;
                }
                if (data.fotf === "-") {
                  delete data.fotf;
                } else {
                  query["fotf.equals"] = data.fotf;
                }
                await getFormatReport(
                  data.tipeModel,
                  data.tipeFile,
                  query
                );
              } else {
                let query = {
                  "thnPajak.equals": data.thnPajak,
                  "identitasDipotong.equals": data.identitasDipotong,
                  "flagFasilitas.notEquals": "DTP",
                  "createdBy.contains": authStore.user.username,
                  // "companyId.equals": authStore.user.company.id.toString()
                };
                if (data.tipeModel === "model2") {
                  delete query["flagFasilitas.notEquals"];
                }
                if (data.fgStatus === "-") {
                  delete data.fgStatus;
                } else {
                  query["fgStatus.equals"] = data.fgStatus;
                }
                if (data.fgLapor === "-") {
                  delete data.fgLapor;
                } else {
                  query["fgLapor.equals"] = data.fgLapor;
                }
                if (data.fotf === "-") {
                  delete data.fotf;
                } else {
                  query["fotf.equals"] = data.fotf;
                }
                await getFormatReport(
                  data.tipeModel,
                  data.tipeFile,
                  query
                );
              }
            }

            // TableWrapper.reload("mod-table-bukti-potong-op");
            callback("Data berhasil diexport", false);
          } catch (error) {
            toast.errorRequest(error);
            callback(error, true, false);
          }
        }}
        submitLabel="Download"
      />
    )
  );
};

export default inject(
  "authStore",
  "envStore"
)(observer(TableBuktiPotongFormExport));
