import { crud, http } from "../../libs/react-mpk/services";

let service = new crud("/");

export const getLogScan = (query) => {
  return http.get(`/log_scan`, query);
};
export const getLogScanSupport = (query) => {
  return http.get(`/support/log_scan`, query);
};
export const getLogScanExport = (query) => {
  return http.get(`/sb/report/log_scan/excel`, query);
};

export default service;