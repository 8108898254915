import { crud, http } from "../../../libs/react-mpk/services";

let service = new crud("/api/admin/proxy");

export const getEmailConfig = () => {
  return http.get(`/api/admin/email_config`);
};
export const postEmailConfig = (data) => {
  return http.post(`/api/admin/email_config`, data);
};
export const putEmailConfig = (id, data) => {
  return http.put(`/api/admin/email_config/${id}`, data);
};

export default service;
