import { crud, http } from "../../../libs/react-mpk/services";

let service = new crud("/api/admin/cron");

export const getDataSceduler = (query) => {
  return http.get(`/api/admin/cron`, query);
};
export const addDataSceduler = (data) => {
  return http.post(`/api/admin/cron`, data);
};
export const getDataScedulerById = (id) => {
  return http.get(`/api/admin/cron/${id}`);
};
export const editDataScedulerById = (id, data) => {
  return http.put(`/api/admin/cron/${id}`, data);
};
export const deleteDataSceduler= (id, item) => {
  return http.delete(`/api/admin/cron/${id}`, item);
};
	



export default service;
