import React from 'react';
import { Router, Switch, Route } from "react-router-dom";
import { sso } from '../libs/react-mpk/hocs';
import { PageWrapper } from '../libs/react-mpk/wrapper';
import { FormSample, BuktiPotong, TableLogImport, TableLogScan} from '../modules';
import { inject, observer } from "mobx-react";


const Internal = ({history, ...props}) => {
  let isSupport = props.authStore.user.isSupport
  let support = {
    subname: isSupport ?'- Support':''
  }
  return (
    <PageWrapper
      sidebarCollapsible={true}
      inverseTheme={true}
    >
      <Router history={history}>
        <Switch>
          <Route
            path='/internal/logImport'
            render={props => (
              <TableLogImport {...props}/>
            )}
          />
           <Route
            path='/internal/logScan'
            render={props => (
              <TableLogScan {...props}/>
            )}
          />
          <Route
            path='/internal/form'
            render={props => (
              <FormSample {...props}/>
            )}
          />
          <Route
            path='/internal/details/:tab'
            render={props => (
              <BuktiPotong {...props}/>
            )}
          />
        </Switch>
      </Router>
    </PageWrapper>
  )
}

export default sso({
  basePath: '/internal'
})(inject("authStore")(observer(Internal)));