import React, { useEffect, useState } from "react";
import { DataForm } from "../../libs/react-mpk/components";
import { cloneDeep } from "lodash";
import { addDataBupot, deleteBupot } from "./TableBuktiPotong.service";
import { addDataBupotSupport, deleteBupotSupport } from "./TableBuktiPotongSupport.service";
import { TableWrapper } from "../../libs/react-mpk/wrapper";
import { DataTable } from "../../libs/react-mpk/components";
import qs from "query-string";
import { toast } from "../../libs/react-mpk/services";
import { inject, observer } from "mobx-react";
import iziToast from "izitoast";

const defaultData = () => ({
  url: "",
});

const TableBuktiPotongFormScanner = ({
  className = "",
  visible = false,
  authStore,
  onRequestClose = () => { },
  ...props
}) => {
  let [item, setItem] = useState(null);
  let [ready, setReady] = useState(true);
  let [dataList, setData] = useState([]);
  let [dataUrl, setUrl] = useState("");
  let [check, setCheck] = useState(false);
  let [scan, setScan] = useState(false);
  let [preview, setPreview] = useState(false);
  let isSupport = authStore.user.isSupport

  useEffect(() => {
    if (!visible) {
      setPreview(false);
      setCheck(false);
    }
  }, [visible]);

  const handleDelete = (id) => {
    let newList = dataList.filter((item) => item.idBupot !== id);
    if (newList.length === 0) {
      setPreview(false);
    }
    // console.log(newList);
    setData(newList);
  };

  const validURL = (str) => {
    var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
  }

  const scannerPDF = async (value) => {
    setTimeout(async () => {
      try {
        toast.info('Scan PDF Started')
        setCheck(true);
        var decodeDjpUrl = btoa(value);
        let response;
        if (isSupport) {
          response = await addDataBupotSupport({
            decodedUrl: value,
            encodedUrl: decodeDjpUrl,
          });
        } else {
          response = await addDataBupot({
            decodedUrl: value,
            encodedUrl: decodeDjpUrl,
          });
        }
        setPreview(true);
        setData((d) => [...d, response.data.data]);
        setCheck(false);
        toast.success('Scan PDF Completed')
      } catch (error) {
        toast.error('Scan PDF Failed')
        setCheck(false);
        console.log(error);
      }
    }, 3000);
  };

  // useEffect(() => {
  // 	if (visible) {
  // 		let selectedItem = defaultData();
  // 		if (props.item) {
  // 			selectedItem = cloneDeep(props.item)
  // 			selectedItem.number = String(selectedItem.number)
  // 		}
  // 		setItem(selectedItem)
  // 	} else {
  // 		setItem(defaultData())
  // 	}
  // 	setReady(visible)
  // }, [visible])

  return (
    ready && (
      <DataForm
        baseId="table-scanner-form"
        title="Form Scanner"
        visible={visible}
        // data={item}
        onRequestClose={onRequestClose}
        asDialog={true}
        defaultData={props.item || defaultData()}
        definitions={[
          {
            inputType: DataForm.inputTypes.INPUT,
            label: "URL",
            key: "url",
          },
          {
            render: (
              <DataTable
                baseId="mod-table-preview-data"
                title="Tabel Review Data"
                className={className}
                data={dataList}
                fit={false}
                loading={false}
                columns={[
                  {
                    label: "Fg Status",
                    sortable: true,
                    searchable: true,
                    key: "fgStatus",
                    render: item => {
                      switch (item.fgStatus) {
                        case '0':
                          return 'Normal'
                        case '1':
                          return 'Pembetulan'
                        case '2':
                          return 'Pembatalan'
                        case '3':
                          return 'Dihapus'
                        default:
                          return '-'
                      }
                    }
                  },
                  {
                    label: "Fg Lapor",
                    sortable: true,
                    searchable: false,
                    key: "fgLapor",
                    render: (item) => (
                      <TableWrapper.Status
                        type={
                          item.fgLapor === "1"
                            ? TableWrapper.Status.types.SUCCESS
                            : TableWrapper.Status.types.ERROR
                        }
                      >
                        {item.fgLapor === "1" ? "Sudah Lapor" : "Belum Lapor"}
                      </TableWrapper.Status>
                    ),
                  },
                  {
                    label: "Fg Fasilitas",
                    searchable: true,
                    sortable: true,
                    key: "fgFasilitas",
                    render: item => {
                      switch (item.fgFasilitas) {
                        case '0':
                          return 'Tanpa Fasilitas'
                        case '1':
                          return 'SKB'
                        case '2':
                          return 'SKD'
                        case '3':
                          return 'DTP'
                        case '4':
                          return 'SKT PP 23 2018'
                        case '5':
                          return 'Fasilitas Lainnya'
                        default:
                          return '-'
                      }
                    }
                  },
                  {
                    label: "Nomor Bukti Potong",
                    searchable: true,
                    sortable: true,
                    key: "nomerBuktiPotong",
                    render: (item) => item.noBupot,
                  },
                  {
                    label: "Nomor Revisi",
                    searchable: true,
                    sortable: true,
                    key: "nomerRevisi",
                    render: (item) => item.revNo,
                  },
                  {
                    label: "Pasal",
                    searchable: true,
                    sortable: true,
                    key: "pasal",
                    render: (item) => item.jnsPasal,
                  },
                  {
                    label: "FOTF",
                    searchable: true,
                    sortable: true,
                    key: "fotf",
                    render: (item) => item.fotf,
                  },
                  {
                    label: "Kode Objek Pajak",
                    searchable: true,
                    sortable: true,
                    key: "kdObjPjk",
                    render: (item) => item.kdObjPjk,
                  },
                  {
                    label: "Jenis Penghasilan",
                    searchable: true,
                    sortable: true,
                    key: "jnsPenghasilan",
                    render: (item) => item.jnsPenghasilan,
                  },
                  {
                    label: "Bruto",
                    searchable: true,
                    sortable: true,
                    key: "jmlBruto",
                    render: (item) => item.jmlBruto,
                  },
                  {
                    label: "Tarif",
                    searchable: true,
                    sortable: true,
                    key: "tarif",
                    render: (item) => item.tarif,
                  },
                  {
                    label: "PPH Dipotong",
                    searchable: true,
                    sortable: true,
                    key: "pphDipotong",
                    render: (item) => item.pphDipotong,
                  },
                  {
                    label: "Tanggal Pemotongan",
                    searchable: true,
                    sortable: true,
                    key: "tglPemotongan",
                    render: (item) => item.tglPemotongan,
                  },
                  {
                    label: "Masa Pajak",
                    searchable: true,
                    sortable: true,
                    key: "masaPajak",
                    render: (item) => item.masaPajak,
                  },
                  {
                    label: "Tahun Pajak",
                    searchable: true,
                    sortable: true,
                    key: "thnPajak",
                    render: (item) => item.thnPajak,
                  },
                  {
                    label: "Identitas Dipotong",
                    searchable: true,
                    sortable: true,
                    key: "identitasDipotong",
                    render: (item) => item.identitasDipotong,
                  },
                  {
                    label: "Nomor Dokumen Dasar Pemotongan",
                    searchable: true,
                    sortable: true,
                    key: "nomorDok",
                    render: (item) => (
                      <div>
                        {item.dokDasarPemotongan.map((b) => (
                          <div key={b.nomerDok} className="mpk-link">
                            {b.nomorDok}
                          </div>
                        ))}
                      </div>
                    ),
                  },
                  {
                    label: "identitas Pemotong Nama Pemotong",
                    searchable: true,
                    sortable: true,
                    key: "nomerDokumen",
                    render: (item) => (
                      <div>{item.identitasPemotong.namaPemotong}</div>
                    ),
                  },
                  {
                    label: "identitas Pemotong Alamat Pemotong",
                    searchable: true,
                    sortable: true,
                    key: "nomerDokumen",
                    render: (item) => (
                      <div>{item.identitasPemotong.alamatPemotong}</div>
                    ),
                  },
                  {
                    label: "identitas Pemotong NPWP Pemotong",
                    searchable: true,
                    sortable: true,
                    key: "nomerDokumen",
                    render: (item) => (
                      <div>{item.identitasPemotong.npwpPemotong}</div>
                    ),
                  },
                ]}
                itemActions={[
                  new TableWrapper.action(
                    "Delete",
                    "mdi mdi-delete",
                    (item, index) => {
                      props.modalStore.showConfirm({
                        title: "Delete",
                        children: "yakin?",
                        onSubmit: async (callback, value) => {
                          try {
                            if (isSupport) {
                              await deleteBupotSupport(item.idBupot);
                            } else {
                              await deleteBupot(item.idBupot);
                            }
                            handleDelete(item.idBupot);
                            callback();
                            toast.success("Data berhasil dihapus");
                          } catch (error) {
                            toast.errorRequest(error);
                            callback();
                          }
                        },
                      });
                    },
                    true
                  ),
                ]}
              />
            ),
          },
        ]}
        // onBeforeChange={async (key, value) => {
        //   return value;
        //   // console.log(value);
        // }}
        onChange={async (data, key, value) => {
          if (key === "url") {
            setPreview(false);
            if (value) {
              let parsed = qs.parseUrl(value);
              let { v1, v2, mel, ken } = parsed.query;
              if (!value.includes("https://")) {
                value = value.replace("ttps://", "https://");
              }
              if (!check) {
                if (value.length === 30 && !value.includes('https://unifikasi.pajak.go.id')) {
                  toast.warning('Tidak Bisa Scan, Url tidak valid!')
                } else if ((v1 && v1.length === 64 && v2 && v2.length === 102) || (mel && mel.length === 64 && ken && ken.length === 102)) {
                  scannerPDF(value);
                  data.url = value;
                }
              }
            }
          }
          return data;
        }}
        // submitLabel="OK"
        submitLabel="KELUAR"
        submitIconClassName=''
        showCancel={false}
        loading={check}
        onSubmit={async (data, callback) => {
          TableWrapper.reload("mod-table-bukti-potong");
          onRequestClose();
          callback()
          // if (preview) {
          //   try {
          //     let filterScan = dataList.filter((d) => d.isSelect);
          //     // var decodeDjpUrl = btoa(dataUrl)
          //     for (let scanFile of filterScan) {
          //       let response = await addDataBupot({
          //         decodedUrl: scanFile.djpUrl,
          //         encodedUrl: scanFile.decodeDjpUrl,
          //       });
          //     }
          //     callback("success", false);
          //     setUrl("");
          //     setData([]);
          //   } catch (error) {
          //     toast.errorRequest(error)
          //   }
          // } else {
          //   callback(null, false, false);
          // }
        }}
      />
    )
  );
};

export default inject("modalStore", "authStore")(observer(TableBuktiPotongFormScanner));
