import React, { useState, useEffect, Children } from "react";
import { DataForm } from "../../../libs/react-mpk/components";
import { TableWrapper } from "../../../libs/react-mpk/wrapper"
import { toast } from "../../../libs/react-mpk/services"
import { inject, observer } from "mobx-react";
import service, { addDataRole, editDataRoleById } from "./TabelRole.service";
import { find } from "lodash";

const { inputTypes } = DataForm;
const AddRole = ({ visible = false, onRequestClose = null, item, ...props }) => {
    const [ready, setReady] = useState(false)
    useEffect(() => {
        if (visible)
            setReady(true)
    }, [visible])
    return ready && (
        <DataForm
            baseId="add-role"
            title="Tambah Role"
            asDialog={true}
            visible={visible}
            onRequestClose={() => {
                setReady(false)
                onRequestClose()
            }}
            defaultData={
                item || {
                    nama: "",
                    deskripsi: "",
                    isAdmin: false,
                    isDefault: false,
                }
            }
            definitions={[
                {
                    inputType: inputTypes.INPUT,
                    label: "Nama",
                    key: "nama",
                    required: true
                },
                {
                    inputType: inputTypes.TEXTAREA,
                    label: "Deskripsi",
                    key: "deskripsi",
                    required: true,
                },
                {
                    inputType: inputTypes.CHECKBOX,
                    label: 'Default',
                    key: "isDefault"
                },
                {
                    inputType: inputTypes.CHECKBOX,
                    label: 'Admin',
                    key: "isAdmin"
                }
            ]}
            onSubmit={async (data, callback) => {
                try {
                    let response = item
                        ? await editDataRoleById(item.id, data)
                        : await addDataRole(data)
                    TableWrapper.updateDataItem("mod-table-role", { id: response.data.id }, response.data)
                    console.log(data);
                    callback(item ? "Data Berhasil Diedit" : "Data Berhasil Ditambah", false)
                } catch (error) {
                    callback(error.response.data.detail, true)
                }
            }}
        />
    );
};

export default inject("envStore")(observer(AddRole));
