import React, { useEffect, useState } from 'react';
import { DataForm } from '../../../libs/react-mpk/components';
import { cloneDeep } from 'lodash'
import { getDataBupot } from './TableBuktiPotong.service';

const defaultData = () => ({
	string: '',
	number: 0,
	boolean: false,
	multiCheckbox: ['']
})

const TableBuktiPotongFormDokumen = ({
	visible = false,
	onRequestClose = () => { },
	...props
}) => {
	let [item, setItem] = useState(null)
	let [ready, setReady] = useState(true)


	return ready && (
		<DataForm
			baseId="table-no-dokumen-form"
			title="Form Dokumen"
			visible={visible}
			// data={item}
			onRequestClose={onRequestClose}
			asDialog={true}
			defaultData={props.item || defaultData()}
			definitions={[
				{
					inputType: DataForm.inputTypes.INPUT,
					label: 'Jenis Dokumen',
					key: 'jnsDok',
					disabled: true
				},
				{
					inputType: DataForm.inputTypes.INPUT,
					label: 'Nama Dokumen',
					key: 'namaDok',
					disabled: true
				},
				{
					inputType: DataForm.inputTypes.INPUT,
					label: 'No Dokumen',
					key: 'nomorDok',
					disabled: true
				},
				{
					inputType: DataForm.inputTypes.INPUT,
					label: 'Tanggal Dokumen',
					key: 'tglDok',
					disabled: true
				}
			]}
		/>
	)
}

export default TableBuktiPotongFormDokumen