import { crud, http } from "../../libs/react-mpk/services";
import qs from "query-string";

let service = new crud("/");

export const getDataBupotSupport = (query, fgLapor) => {
  query["fgLapor.equals"] = fgLapor
  return http.get(`/support/sb`, query);
};

export const getSyncBulkSupport = (data) => {
  return http.get(`/support/sb/syncBupot/bulk?${qs.stringify(data)}`);
};

export const getSyncBulkByidSupport = (id) => {
  return http.get(`/support/sb/syncBupot/single/${id}`);
};

export const getFormatReportSupport= (tipeModel,tipeFile,query) => {
  return http.get(`/support/sb/report/${tipeModel}/${tipeFile}?${qs.stringify(query)}`);
};

export const downloadReportSupport= (filename) => {
  return http.download(`/support/sb/download/${filename}`);
};

export const getDataBupotByIdSupport = (id, query) => {
  return http.get(`/support/sb/${id}`, query);
};

export const addDataBupotSupport = (data) => {
  return http.post(`/support/sb`, data);
};

export const deleteBupotSupport  = (id, data) => {
  return http.delete(`/support/sb/${id}`, data);
};

export const updateBupotSupport  = (id, data) => {
  return http.put(`/support/sb/${id}`, data);
};
export const updateBupotRefSupport  = (id, data) => {
  return http.put(`/support/sb/${id}/referensi`, data);
};


export default service;