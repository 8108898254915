import { crud, http } from "../../../libs/react-mpk/services";

let service = new crud("/api/admin/role");

export const getDataRole = (query) => {
  return http.get(`/api/admin/role`, query);
};
export const addDataRole = (data) => {
  return http.post(`/api/admin/role`, data);
};
export const getDataRoleById = (id) => {
  return http.get(`/api/admin/role/${id}`);
};
export const editDataRoleById = (id, data) => {
  return http.put(`/api/admin/role/${id}`, data);
};
export const deleteDataRole= (id, item) => {
  return http.delete(`/api/admin/role/${id}`, item);
};
	



export default service;
