import React, { useState } from 'react'
import { TableWrapper } from '../../../libs/react-mpk/wrapper'
import t from 'counterpart'
import service, { getDataUser, deleteDataUser } from './TabelUser.service'
import moment from 'moment'
import { inject, observer } from "mobx-react";
import AddUser from "./AddUser";
import { toast } from '../../../libs/react-mpk/services'

const TableUser = ({
  className = '',
  showCommandbar = true,
  title,
  ...props
}) => {
  let [showForm, setShowForm] = useState(false);
  let [selectedItem, setSelectedItem] = useState(null)
  return (
    <>
      <TableWrapper
        baseId="mod-table-user"
        title="User List"
        className={className}
        // hintMessage={t.translate('modules.table.hint.message')}
        hintIconClassName="mdi mdi-information"
        hintMore={t.translate('modules.table.hint.more')}
        defaultData={[]}
        defaultSortBy="nama"
        useCriteria={true}
        columns={[
          {
            label: 'Nama',
            sortable: true,
            searchable: true,
            // render: item => item.nama,
            render: item => {
              if(item.role === 'ROLE_USER'){
                return(
                  <TableWrapper.Link
                    to={`/general/index/user_npwp/${item.id}`}
                  >
                  {item.nama}
                </TableWrapper.Link>
                )
              }else{
                return item.nama
              }
            }
          },
          {
            label: 'Username',
            sortable: true,
            searchable: true,
            render: item => item.username

          },
          {
            label: 'Email',
            sortable: true,
            searchable: true,
            render: item => item.email,
          },
          {
            label: 'Role',
            sortable: true,
            searchable: true,
            render: item => item.role

          },
        ]}
        actions={[
          new TableWrapper.action('Add', 'mdi mdi-plus', () => {
            setShowForm(true)
            setSelectedItem(null)
          }, true)
        ]}
        itemActions={[
          new TableWrapper.action('Edit', 'mdi mdi-pencil', (item) => {
            setShowForm(true)
            setSelectedItem(item)
          }, true),
          new TableWrapper.action('Delete', 'mdi mdi-delete', (item) => {
            props.modalStore.showConfirm({
              title: "Delete",
              children: "yakin?",
              onSubmit: async (callback) => {
                try {
                  await deleteDataUser(item.id)
                  TableWrapper.reload("mod-table-user")
                  callback()
                  toast.success("Member berhasil dihapus")
                } catch (error) {
                  toast.errorRequest(error)
                  callback()
                }

              }
            })
          },
            true)
        ]}
        onFetchData={async (query) => {
          return getDataUser(query)
        }}
        showCommandbar={showCommandbar}
        showFilterPeriod={false}
      />
      <AddUser
        visible={showForm}
        onRequestClose={() => setShowForm(false)}
        item={selectedItem} />
    </>
  )
}

export default inject("modalStore")(observer(TableUser))
