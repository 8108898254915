import React, { useEffect, useState } from 'react';
import { DataForm } from '../../libs/react-mpk/components';
import { inject, observer } from 'mobx-react'
import { getSyncBulk } from "./TableBuktiPotong.service";
import { getSyncBulkSupport } from "./TableBuktiPotongSupport.service";
import moment from 'moment';
import {TableWrapper} from "../../libs/react-mpk/wrapper"
import { toast } from '../../libs/react-mpk/services';
import { useParams } from 'react-router'
const defaultData = () => ({
	thnPajak: "2019"
})

const TableBuktiPotongFormSync = ({
	visible = false,
	onRequestClose = () => { },
	envStore,
	authStore,
	...props
}) => {
	let [item, setItem] = useState(null)
	let [ready, setReady] = useState(true)
	const [years, setYears] = useState([])
	let isSupport = authStore.user.isSupport
  const { companyId } = useParams()
	const generateYears = () => {
		let currentYear = moment(envStore.env.timestamp).year()
		for (let y = currentYear; y >= 2019; y--) {
			years.push(String(y))
		}
		setYears(years)
	}

	useEffect(() => {
		generateYears()
	}, [])

	return ready && (
		<DataForm
			baseId="table-sync"
			title="Form Sync"
			visible={visible}
			// data={item}
			onRequestClose={onRequestClose}
			asDialog={true}
			defaultData={defaultData(envStore)}
			definitions={[
				{
					inputType: DataForm.inputTypes.SELECT,
					label: 'Tahun Pajak',
					key: "thnPajak",
					options: years

				}
			]}
			onSubmit={async (data, callback) => {
				try {
					let query = {
						"thnPajak.equals": data.thnPajak
					}
					if(isSupport){
						query['companyId.equals'] = companyId
						await getSyncBulkSupport(query)
					}else{
						await getSyncBulk(query)
					}
					TableWrapper.reload("mod-table-bukti-potong")
					callback("Data Berhasil disync", false)
				} catch (error) {
					toast.errorRequest(error)
					callback(error)
				}

			}}
			submitLabel="Sync"
		/>
	)
}


export default inject('envStore','authStore')(observer(TableBuktiPotongFormSync))