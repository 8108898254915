import { crud, http } from "../../../libs/react-mpk/services";

let service = new crud("/api/admin/user");

export const getDataUser = (query) => {
  return http.get(`/api/admin/user`, query);
};
export const addDataUser = (data) => {
  return http.post(`/api/admin/user`, data);
};
export const getDataUserById = (id) => {
  return http.get(`/api/admin/user/${id}`);
};
export const editDataUserById = (id, data) => {
  return http.put(`/api/admin/user/${id}`, data);
};
export const deleteDataUser= (id, item) => {
  return http.delete(`/api/admin/user/${id}`, item);
};
	



export default service;
