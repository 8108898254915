import { crud, http } from "../../../libs/react-mpk/services";

let service = new crud("/api/admin/sftp");

export const getDataSftp = (query) => {
  return http.get(`/api/admin/sftp`, query);
};
export const addDataSftp = (data) => {
  return http.post(`/api/admin/sftp`, data);
};
export const getDataSftpById = (id) => {
  return http.get(`/api/admin/sftp/${id}`);
};
export const editDataSftpById = (id, data) => {
  return http.put(`/api/admin/sftp/${id}`, data);
};
export const deleteDataSftp= (id, item) => {
  return http.delete(`/api/admin/sftp/${id}`, item);
};
	



export default service;
