import { crud, http } from "../../../libs/react-mpk/services";

let service = new crud("/api/admin/user");

export const getDataNpwp = (query) => {
  return http.get(`/api/admin/npwp`, query);
};
export const addDataNpwp = (file) => {
  return http.upload(`/api/admin/npwp`, file);
};
export const getDataNpwpById = (id) => {
  return http.get(`/api/admin/npwp/${id}`);
};
export const editDataNpwpById = (id, data) => {
  return http.put(`/api/admin/npwp/${id}`, data);
};
export const deleteDataNpwp= (id, item) => {
  return http.delete(`/api/admin/npwp/${id}`, item);
};
	



export default service;
