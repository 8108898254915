import { crud, http } from "../../../libs/react-mpk/services";

let service = new crud("/api/sb/sftp");

export const getDataSftpUser = (query) => {
  return http.get(`/api/sb/sftp`, query);
};
export const addDataSftpUser = (data) => {
  return http.post(`/api/sb/sftp`, data);
};
export const getDataSftpByIdUser = (id) => {
  return http.get(`/api/sb/sftp/${id}`);
};
export const editDataSftpByIdUser = (id, data) => {
  return http.put(`/api/sb/sftp/${id}`, data);
};
export const deleteDataSftpUser = (id, item) => {
  return http.delete(`/api/sb/sftp/${id}`, item);
};
	



export default service;
