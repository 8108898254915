import React, { useState, useEffect } from "react";
import { DataForm } from "../../../libs/react-mpk/components";
import {TableWrapper} from "../../../libs/react-mpk/wrapper"
import {toast} from "../../../libs/react-mpk/services"
import { inject, observer } from "mobx-react";
import {updateBupotRef} from "./TableBuktiPotong.service"


const { inputTypes } = DataForm;

const TambahReferensi = ({ visible = false, onRequestClose = null, item = null, isEdit = false, authStore,...props }) => {
  let isSupport = authStore.user.isSupport
  const [ready, setReady] = useState(false)
  useEffect(()=>{
    if (visible)
    setReady(true)
  }, [visible])
  const [defData, setDefData] = useState({
    referensi1 : '',
    referensi2 : '',
    referensi3 : '',
    referensi4 : '',
  })
  useEffect(()=>{
    if(item && item.referensi){
      let arrayRef = item.referensi.split(";")
      setDefData({
        referensi1 : arrayRef[0]?arrayRef[0]:'',
        referensi2 : arrayRef[1]?arrayRef[1]:'',
        referensi3 : arrayRef[2]?arrayRef[2]:'',
        referensi4 : arrayRef[3]?arrayRef[3]:'',
      })
    }
  },[visible])


  return ready && (
    <DataForm
      baseId="update-referensi"
      title="Referensi"
      asDialog={true}
      visible={visible}
      onRequestClose={()=>{
        setReady(false)
        onRequestClose()
      }}
      defaultData={
        defData
      }
      onInitData={(data)=>{
        if (data.status) {
          data.statusId = data.status.id    
        } 
        return data 
      }}
      definitions={[
        {
          inputType: inputTypes.INPUT,
          label: "Referensi 1",
          key: "referensi1",
          required: false
        },
        {
          inputType: inputTypes.INPUT,
          label: "Referensi 2",
          key: "referensi2",
          required: false
        },
        {
          inputType: inputTypes.INPUT,
          label: "Referensi 3",
          key: "referensi3",
          required: false
        },
        {
          inputType: inputTypes.INPUT,
          label: "Referensi 4",
          key: "referensi4",
          required: false
        },
      ]}
      onSubmit={async (data, callback, res) => {
          try {
            let dataref = {
              referensi: `${data.referensi1?data.referensi1+';':''}${data.referensi2?data.referensi2+';':''}${data.referensi3?data.referensi3+';':''}${data.referensi4?data.referensi4+';':''}`
            }
            await updateBupotRef(item.idBupot, dataref)
            TableWrapper.reload("mod-table-akhiri-layanan")
            callback("Referensi Berhasil Diupdate", false)
          } catch (error) {
            callback(error)
          }
      }}
    />
  );
};

export default inject("envStore", "authStore")(observer(TambahReferensi));
