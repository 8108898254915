import React, { useState, useEffect, Children } from "react";
import { DataForm } from "../../../libs/react-mpk/components";
import { TableWrapper } from "../../../libs/react-mpk/wrapper"
import { toast } from "../../../libs/react-mpk/services"
import { inject, observer } from "mobx-react";
import service, { addDataUser, editDataUserById } from "./TabelUser.service";
import { getDataRole } from "../TabelRoles/TabelRole.service";
import { find } from "lodash";
import { options } from "superagent";

const { inputTypes } = DataForm;
const AddUser = ({ visible = false, onRequestClose = null, item, ...props }) => {
    const [ready, setReady] = useState(false)
    const [roleList, setRoleList] = useState([])
    useEffect(async() => {
          const dataRole = await getDataRole({ page: 0, size: 50 })
          setRoleList(dataRole.data)
        if (visible)
            setReady(true)
    }, [visible])
    return ready && (
        <DataForm
            baseId="add-user"
            title="Tambah User"
            asDialog={true}
            visible={visible}
            onRequestClose={() => {
                setReady(false)
                onRequestClose()
            }}
            defaultData={
                item ?
                {...item, password: ''} : {
                    nama: "",
                    username: "",
                    role: roleList[0].nama,
                    password: "",
                    konfirmasi_password: "",
                    isActive: false,
                }
            }
            definitions={[
                {
                    inputType: inputTypes.CHECKBOX,
                    label: 'IsAktive',
                    key: "isActive"
                },
                {
                    inputType: inputTypes.INPUT,
                    label: "Nama",
                    key: "nama",
                    required: true,
                },
                {
                    inputType: inputTypes.INPUT,
                    label: "Username",
                    key: "username",
                    maxLength: 25,
                    minLength: 3,
                    required: true,
                    disabled: item? true:false,
                },
                {
                    inputType: inputTypes.INPUT,
                    label: "Email",
                    key: "email",
                    validation: 'email',
                    required: true,
                },
                {
                    inputType: inputTypes.SELECT,
                    label: 'Role',
                    key: "role",
                    options: roleList.map(d => ({
                        label: d.nama,
                        value: d.nama
                    })),
                    required: true,
                },
                {
                    inputType: inputTypes.INPUT,
                    label: 'Password',
                    className: 'mpk-padding-N padding-right',
                    key: "password",
                    minLength: 8,
                    type: 'password',
                    width: '50%',
                    // required: true,
                    // show: item?false:true
                },
                {
                    inputType: inputTypes.INPUT,
                    label: 'Konfirmasi Password',
                    key: "konfirmasi_password",
                    type: 'password',
                    width: '50%',
                    // required: true,
                    // show: item?false:true
                }
            ]}
            onChange={(data, key, value)=>{
                if(key === 'username'){
                    data.username = value.toLowerCase()
                }
                return data
            }}
            onSubmit={async (data, callback) => {
                if(!data.username.match(/^[0-9a-z]+$/)){
                    callback('username tidak boleh ada simbol atau space', true, false)
                }else if(data.password && data.password !== data.konfirmasi_password){
                    callback('Konfirmasi Password tidak sama dengan password', true, false)
                }else{
                    try {
                        if(!data.password){
                            data.password = null
                            delete data.konfirmasi_password
                        }
                        let response = item
                            ? await editDataUserById(item.id, data)
                            : await addDataUser(data)
                        TableWrapper.updateDataItem("mod-table-user", { id: response.data.id }, response.data)
                        callback(item ? "Data Berhasil Diedit" : "Data Berhasil Ditambah", false)
                    } catch (error) {
                        callback(error.response.data.detail, true)
                    }
                }
            }}
        />
    );
};

export default inject("envStore")(observer(AddUser));
