class action {
  constructor(
    label = 'string',
    iconClassName = 'mdi mdi-arrow-right',
    onClick = null,
    show = true,
    resourceUri = null,
  ) {
    this.label = label
    this.iconClassName = iconClassName
    this.onClick = onClick
    this.show = show
    this.resourceUri = resourceUri
  }
}

export default action