import { crud, http } from "../../../libs/react-mpk/services";
import qs from "query-string";

let service = new crud("/");

export const getDataBupot = (query, fgLapor) => {
  query["fgLapor.equals"] = fgLapor
  return http.get(`/api/sb`, query);
};
export const DownloadPdfBupot = (query) => {
  return http.get(`/api/sb/bupot/file`, query);
};

export const getSyncBulk = (data) => {
  return http.get(`/api/sb/syncBupot/bulk?${qs.stringify(data)}`);
};

export const getSyncBulkByid = (id) => {
  return http.get(`/api/sb/syncBupot/single/${id}`);
};

export const getFormatReport= (tipeModel,tipeFile,query) => {
  return http.get(`/api/sb/report/${tipeModel}/${tipeFile}?${qs.stringify(query)}`);
};

export const downloadReport= (filename) => {
  return http.download(`/api/sb/download/${filename}`);
};

export const getDataBupotById = (id, query) => {
  return http.get(`/api/sb/${id}`, query);
};

export const addDataBupot = (data) => {
  return http.post(`/api/sb/scan`, data);
};
export const addDataBupotUpload = (files,data) => {
  return http.upload(`/api/sb`, files, data, {});
};

export const deleteBupot  = (id, data) => {
  return http.delete(`/api/sb/${id}`, data);
};

export const updateBupot  = (id, data) => {
  return http.put(`/api/sb/${id}`, data);
};
export const updateBupotRef  = (id, data) => {
  return http.put(`/api/sb/${id}/referensi`, data);
};


export default service;