import React, { useState, useEffect } from 'react'
import { Button, FontIcon } from 'react-md'
import Modal from '../Modal'
import LoaderInfo from '../LoaderInfo'
import Pagination from '../Pagination'
import t from 'counterpart'
import './Notification.scss'
import { defaultQueryParameter } from '../../config/constant'
import {toast, http} from '../../services'

const Notification = ({
    baseId              = 'mpk-notification',
    iconClassName       = 'mdi mdi-bell',
    count               = 0,
    onFetchData         = null,
    useCriteria         = true,
    totalDataKey        = null,
    render              = null,
    onOpen              = () => {},
    dialogStyle         = {width: '100%', maxWidth: 640},
    ...props
}) => {
    const [showModal, setShowModal ] = useState(false)
    const [query, setQuery] = useState(defaultQueryParameter())
    const [data, setData] = useState([])
    const [totalData, setTotalData] = useState(0)
    const [loading, setLoading] = useState(false)

    const handleFetchData = async () => {
        if(onFetchData){
          setLoading(true)
          setData([])
          try {
            // console.log(useCriteria)
            let res = await onFetchData(useCriteria 
                ? http.mapQueryCriteria(query) 
                : query
            )
            setData(res.data)
            setTotalData(Number(res.headers[ totalDataKey ? totalDataKey : (useCriteria ? 'x-total-count' : 'x-pagination-count')]))
            setLoading(false)
          } catch (error) {
            setLoading(false)
            toast.errorRequest(error)
          }
        }
      }

    useEffect(() => {
        handleFetchData()
    }, [query,showModal])

    return (
        <div className="mpk-notification">
            <Button
                buttonType="icon"
                onClick={() => {
                    setShowModal(true)
                    onOpen(data)
                }}
            >
                <FontIcon iconClassName={iconClassName}/>
                {count > 0 && (
                    <div className="badges">
                        {count}
                    </div>
                )}
            </Button>
            <Modal.Info
                baseId={baseId}
                title={t.translate('mpk.column.notification')}
                visible={showModal}
                onRequestClose={() => setShowModal(false)}
                dialogStyle={dialogStyle}
            >
                {loading ? <LoaderInfo>{t.translate('mpk.sentence.loadingData')}</LoaderInfo> : data.map((d,i) => {
                    return (
                        <div 
                            className="notification-item mpk-padding-N padding-top padding-bottom mpk-border thin solid dark border-top"
                            key={`${baseId}-item-${i}`}
                        >
                            {render ? render(d) : null}
                        </div>
                    )
                })}
                <Pagination
                    {...query}
                    totalData={totalData}
                    className="mpk-margin-N margin-top"
                    onChange={(q) => {
                        setQuery({...query, ...q})
                    }}
                    justify="justify-start"
                />
            </Modal.Info>
        </div>
    )
}

export default Notification
