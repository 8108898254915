import React, { useEffect, useState } from "react";
import { FormWrapper } from "../../../libs/react-mpk/wrapper";
import { inject, observer } from "mobx-react";
import { DataForm } from "../../../libs/react-mpk/components";
import {
  getEmailConfig,
  postEmailConfig,
  putEmailConfig,
} from "./EmailSetting.service";
// import { inputTypes } from "../../../libs/react-mpk/config/constant";
// import t from "counterpart";
// import { find } from "lodash";

const EmailSetting = () => {
  const { inputTypes } = FormWrapper;
  const [emailConfigData, setEmailConfigData] = useState(null);

  const fetchEmailConfig = async () => {
    try {
      const res = await getEmailConfig();
      if (res?.data) setEmailConfigData(res?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchEmailConfig();
  }, []);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-starat",
      }}
    >
      <div style={{ margin: "2em 0" }}>
        <DataForm
          baseId="mod-form-sample"
          // title={t.translate("modules.form.title")}
          hintMessage="Konfigurasi Email"
          hintShowIcon={false}
          submitLabel="Simpan"
          defaultData={{
            username: emailConfigData?.username || "",
            password: "",
            host: emailConfigData?.host || "",
            port: emailConfigData?.port || "",
            fromName: emailConfigData?.fromName || "",
            fromEmail: emailConfigData?.fromEmail || "",
            toName: emailConfigData?.toName || "",
            toEmail: emailConfigData?.toEmail || "",
            protocol: emailConfigData?.protocol || "",
            tls: emailConfigData?.tls || false,
            auth: emailConfigData?.auth || false,
          }}
          definitions={[
            {
              inputType: inputTypes.INPUT,
              className: "mpk-padding-N padding-right",
              label: "Username",
              key: "username",
              width: "50%",
              required: true,
            },
            {
              inputType: inputTypes.INPUT,
              className: "mpk-padding-N padding-right",
              label: "Password",
              key: "password",
              type: "password",
              width: "50%",
              required: true,
            },
            {
              inputType: inputTypes.INPUT,
              className: "mpk-padding-N padding-right",
              label: "Host",
              key: "host",
              width: "50%",
              required: true,
            },
            {
              inputType: inputTypes.INPUT,
              className: "mpk-padding-N padding-right",
              label: "Port",
              key: "port",
              width: "50%",
              required: true,
            },
            {
              inputType: inputTypes.INPUT,
              className: "mpk-padding-N padding-right",
              label: "Nama (Dari)",
              key: "fromName",
              width: "50%",
              required: true,
            },
            {
              inputType: inputTypes.INPUT,
              className: "mpk-padding-N padding-right",
              label: "Email (Dari)",
              key: "fromEmail",
              width: "50%",
              required: true,
              validation: "email",
            },
            {
              inputType: inputTypes.INPUT,
              className: "mpk-padding-N padding-right",
              label: "Nama (Ke)",
              key: "toName",
              width: "50%",
              required: true,
            },
            {
              inputType: inputTypes.INPUT,
              className: "mpk-padding-N padding-right",
              label: "Email (Ke)",
              key: "toEmail",
              width: "50%",
              required: true,
              validation: "email",
            },
            {
              inputType: inputTypes.INPUT,
              label: "Protocol",
              key: "protocol",
              required: true,
            },
            {
              inputType: inputTypes.CHECKBOX,
              className: "mpk-padding-N padding-right",
              label: "TLS",
              key: "tls",
              width: "50%",
            },
            {
              inputType: inputTypes.CHECKBOX,
              className: "mpk-padding-N padding-right",
              label: "Auth",
              key: "auth",
              width: "50%",
            },
          ]}
          onChange={(data, key, value) => {
            // if (key === "cityName") {
            //   let city = find(cities, { name: value });
            //   if (city) data.cityId = city.id;
            // }
            return data;
          }}
          onSubmit={async (data, callback) => {
            try {
              if (data.username.match(/^[0-9a-z]+$/))
                callback(
                  "username tidak boleh ada simbol atau space",
                  true,
                  false
                );
              if (!emailConfigData) await postEmailConfig(data);
              if (emailConfigData)
                await putEmailConfig(emailConfigData?.id, data);

              callback("Berhasil Update Pengaturan Email!", false);
              console.log(data);
            } catch (error) {
              callback(error, true);
            }
          }}
        />
      </div>
      {/* <div style={{ marginBottom: "2em", width: "100%" }}>
        <DataForm
          baseId="mod-form-sample"
          // title={t.translate("modules.form.title")}
          hintMessage="Konfigurasi Lainnya"
          hintShowIcon={false}
          defaultData={{
            autosend: false,
            filechecksum: true,
            filename: true,
            limit: false,
            callbackapi: false,
          }}
          submitLabel="Simpan"
          definitions={[
            {
              inputType: inputTypes.CHECKBOX,
              className: "mpk-padding-N padding-right",
              label: "Auto Send Mail Document",
              key: "autosend",
              width: "50%",
              required: true,
            },
            {
              inputType: inputTypes.CHECKBOX,
              className: "mpk-padding-N padding-right",
              label: "Limit",
              key: "limit",
              width: "50%",
              required: true,
            },
            {
              inputType: inputTypes.CHECKBOX,
              className: "mpk-padding-N padding-right",
              label: "Check File Checksum",
              key: "filechecksum",
              width: "50%",
              required: true,
            },
            {
              inputType: inputTypes.CHECKBOX,
              className: "mpk-padding-N padding-right",
              label: "Callback API",
              key: "callbackapi",
              width: "50%",
              required: true,
            },
            {
              inputType: inputTypes.CHECKBOX,
              className: "mpk-padding-N padding-right",
              label: "Check File Name",
              key: "filename",
              width: "50%",
              required: true,
            },
          ]}
          onChange={(data, key, value) => {
            // if (key === "cityName") {
            //   let city = find(cities, { name: value });
            //   if (city) data.cityId = city.id;
            // }
            return data;
          }}
          onSubmit={(data, callback) => {
            console.log(data);
            setTimeout(callback, 2000);
          }}
        />
      </div> */}
    </div>
  );
};

export default inject("envStore")(observer(EmailSetting));
