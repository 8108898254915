import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { inject, observer } from 'mobx-react'
// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { toast } from "../../libs/react-mpk/services";
import { Logo } from '../../libs/react-mpk/components'
import {Modal} from '../../libs/react-mpk/components'
import renderHTML from 'react-render-html'
import qs from 'query-string'



import { getUploadAggrement } from "./Login.service"
import jwt_decode from "jwt-decode";
import fs from "fs";
import path from "path";


import { LoginService, ResetService } from './Login.service';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://pajakku.com/">
        Pajakku.com
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

function SignIn({authStore, envStore, navigationStore}) {
  let parsedUrl = qs.parseUrl(window.location.href)
	let { token } = parsedUrl.query
  console.log("🚀 ~ file: ResetPassword.js ~ line 58 ~ SignIn ~ token", token)
  const [showPassword, setShowPassword] = React.useState(false);
  const [showPasswordKonfirmasi, setShowPasswordKonfirmasi] = React.useState(false);
  const [username, setUsername] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [passwordKonfirmasi, setPasswordKonfirmasi] = React.useState('')
  const [errormsg, setErrormsg] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [agreement, setAgreement] = React.useState(null)
  const [showAgreement, setShowAgreement] = React.useState(false)


  React.useEffect(async () => {
    try {
        if(!agreement){
            let res = await getUploadAggrement()
            setAgreement(res.data)
        }
    } catch (error) {
        toast.errorRequest(error)
    }
},[])
  
  React.useEffect(()=>{
  },[])
  const handleSubmit = async () => {
    try {
      if(password !== passwordKonfirmasi){
        throw new Error("Password baru dan konfirmasi password tidak sama")
      }
      setLoading(true)
      let response =  await ResetService({
        token: token,
        password: password
      })
      await navigationStore.redirectTo('/general/login')
      setLoading(false)
    } catch (error) {
      setLoading(false)
      setErrormsg(error.message);
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClickShowPasswordKonfirmasi = () => {
    setShowPasswordKonfirmasi(!showPasswordKonfirmasi);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            paddingTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {/* <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}> */}
            {/* <LockOutlinedIcon /> */}
          {/* </Avatar> */}
          <Box sx={{ m: 2 }}>
            <Logo />
          </Box>
          <Typography component="h1" variant="h5">
            Reset Password
          </Typography>
          <Box component="form" noValidate sx={{ mt: 1 }}>
            <FormControl fullWidth variant="outlined" style={{
              marginBottom: 15,
            }}>
              <InputLabel htmlFor="outlined-adornment-password">New Password *</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(e)=>{
                  setPassword(e.target.value)
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="New Password"
              />
            </FormControl>
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">Konfirmasi Password *</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPasswordKonfirmasi ? 'text' : 'password'}
                value={passwordKonfirmasi}
                onChange={(e)=>{
                  setPasswordKonfirmasi(e.target.value)
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPasswordKonfirmasi}
                      edge="end"
                    >
                      {showPasswordKonfirmasi ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Konfirmasi Password"
              />
            </FormControl>
            {errormsg &&
              <Typography style={{color: 'red', textAlign: 'center'}}>
                {errormsg}
              </Typography>
            }
            
              <Button
                style={{
                  backgroundColor:"#c34528"
                }}
                // type="submit"
                onClick={()=> handleSubmit()}
                fullWidth
                disabled={loading}
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                {loading ?
                <CircularProgress
                  style={{
                    color: 'white',
                    width: 25,
                    height: 25,
                  }}
                  // sx={{ mt: 3, mb: 2 }}
                />
                :
                'Reset Password'
                }
              </Button>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
      <Modal.Info
        baseId                = 'mpk-modal'
        title                 = {'Syarat dan Ketentuan'}
        visible               = {showAgreement}
        onRequestClose        = {() => {
          setShowAgreement(false)
        }}
      >
        <div>
          {agreement && renderHTML(agreement)}
        </div>
      </Modal.Info>
    </ThemeProvider>
  );
}

export default inject('authStore', 'envStore', 'navigationStore')(observer(SignIn))