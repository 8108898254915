import React, { useEffect } from "react";
import { CompanyCard, Masonry } from "../../libs/react-mpk/components";
import { inject, observer } from "mobx-react";
import t from "counterpart";
import { TableWrapper } from "../../libs/react-mpk/wrapper";
import httpService from "../../libs/react-mpk/services/http.service";
import { http } from "../../libs/react-mpk/services";

const Companies = ({ authStore, envStore }) => {
  useEffect(() => {
    http.setCompanyId(null);
  }, []);
  return (
    <TableWrapper
      title={t.translate("menu.companies")}
      baseId="mod-companies"
      useFilter={authStore.user ? authStore.user.isSupport : false}
      useCriteria={!authStore.user.isSupport}
      columns={[
        {
          label: "Name",
          searchable: true,
          key: "name",
          render: (item) => item.name,
        },
        {
          label: "NPWP",
          searchable: true,
          key: "npwp",
          render: (item) => null,
        },
      ]}
      render={(data) => (
        <div className="mpk-padding-N padding-all mpk-animation slide-in mpk-full full-width">
          <Masonry
            className="mpk-full full-width"
            baseId="mod-companies-masonry"
            items={data.map((d) => (
              <CompanyCard
                key={`company-${d.id}`}
                value={d}
                href={`/product/company/${d.id}`}
              />
            ))}
          />
        </div>
      )}
      onFetchData={(query) => {
        if (query.column) {
          query.field = query.column;
          delete query.column;
        }
        if (query.columnCriteria) {
          delete query.columnCriteria;
        }

        if (query.keyword) {
          query.query = query.keyword;
          delete query.keyword;
        }
        if (authStore.user.isSupport) {
          let res = httpService.stream(
            `${envStore.env.sso.host}/api/user_console/company_support`,
            query,
            {},
            {
              useDefaultHost: false,
              useDefaultBaseUrl: false,
            }
          );
          return res;
        } else {
          let res_ = new Promise((resolve) => {
            let { companies } = authStore.user;
            resolve({
              data: companies,
              headers: {
                "x-pagination-count": companies.length,
              },
            });
          });
          return res_;
        }
      }}
    />
  );
};

export default inject("authStore", "envStore")(observer(Companies));
