import React, { useState } from 'react'
import { TableWrapper } from '../../../libs/react-mpk/wrapper'
import t from 'counterpart'
import service, { getDataRole, deleteDataRole } from './TabelRole.service'
import moment from 'moment'
import { inject, observer } from "mobx-react";
import AddRole from "./AddRole";
import { toast } from '../../../libs/react-mpk/services'

const TableRole = ({
  className = '',
  showCommandbar = true,
  title,
  ...props
}) => {
  let [showForm, setShowForm] = useState(false);
  let [selectedItem, setSelectedItem] = useState(null)
  return (
    <>
      <TableWrapper
        baseId="mod-table-role"
        title="Role List"
        className={className}
        // hintMessage={t.translate('modules.table.hint.message')}
        hintIconClassName="mdi mdi-information"
        hintMore={t.translate('modules.table.hint.more')}
        defaultData={[]}
        showActionColumn={false}
        defaultSortBy="createdDate"
        useCriteria={true}
        columns={[
          {
            label: 'Nama',
            sortable: true,
            searchable: true,
            onclick: item =>{
              console.log('klik');
            },
            render: item => item.nama
          },
          {
            label: 'Deskripsi',
            sortable: true,
            searchable: true,
            render: item => item.deskripsi

          },
          {
            label: 'Admin',
            sortable: true,
            searchable: true,
            key: 'fgLapor',
            render: item => (
              <TableWrapper.Status
                type={item.isAdmin
                  ? TableWrapper.Status.types.SUCCESS
                  : TableWrapper.Status.types.ERROR
                }
              >
                {item.isAdmin ? 'Admin' : 'Bukan Admin'}
              </TableWrapper.Status>
            )
          },
          {
            label: 'Default',
            sortable: true,
            searchable: true,
            key: 'fgLapor',
            render: item => (
              <TableWrapper.Status
                type={item.isDefault
                  ? TableWrapper.Status.types.SUCCESS
                  : TableWrapper.Status.types.ERROR
                }
              >
                {item.isDefault ? 'Is Default' : 'No Default'}
              </TableWrapper.Status>
            )
          },
          {
            label: 'Created By',
            searchable: true,
            sortable: true,
            key: 'createdBy',
            render: item => (
              <div>{item.createdBy}</div>
            )
          }
        ]}
        // actions={[
        //   new TableWrapper.action('Add', 'mdi mdi-plus', () => {
        //     setShowForm(true)
        //     setSelectedItem(null)
        //   }, true)
        // ]}
        // itemActions={[
          // new TableWrapper.action('Edit', 'mdi mdi-pencil', (item) => {
          //   setShowForm(true)
          //   setSelectedItem(item)
          // }, true),
          // new TableWrapper.action('Delete', 'mdi mdi-delete', (item) => {
          //   props.modalStore.showConfirm({
          //     title: "Delete",
          //     children: "yakin?",
          //     onSubmit: async (callback) => {
          //       try {
          //         await deleteDataRole(item.id)
          //         TableWrapper.reload("mod-table-role")
          //         callback()
          //         toast.success("Member berhasil dihapus")
          //       } catch (error) {
          //         toast.errorRequest(error)
          //         callback()
          //       }

          //     }
          //   })
          // },
          //   true)
        // ]}
        onFetchData={async (query) => {
          return getDataRole(query)
        }}
        showCommandbar={showCommandbar}
        showFilterPeriod={false}
      />
      <AddRole
        visible={showForm}
        onRequestClose={() => setShowForm(false)}
        item={selectedItem} />
    </>
  )
}

export default inject("modalStore")(observer(TableRole))
