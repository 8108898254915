import React, { useState, useEffect, Children } from "react";
import { DataForm } from "../../../libs/react-mpk/components";
import { TableWrapper } from "../../../libs/react-mpk/wrapper"
import { toast } from "../../../libs/react-mpk/services"
import { inject, observer } from "mobx-react";
import service, { addDataSceduler, getDataSceduler, editDataScedulerById } from "./TabelSceduler.service";
import { find } from "lodash";

const { inputTypes } = DataForm;
const AddSceduler = ({ visible = false, onRequestClose = null, item, ...props }) => {
    const [ready, setReady] = useState(false)
    useEffect(() => {
        if (visible)
            setReady(true)
    }, [visible])
    return ready && (
        <DataForm
            baseId="add-cron"
            title="Tambah Sceduler"
            asDialog={true}
            visible={visible}
            onRequestClose={() => {
                setReady(false)
                onRequestClose()
            }}
            defaultData={
                item || {
                    cron: "",
                    nama: "",
                    pathFile: "",
                    isActive: true,
                }
            }
            definitions={[
                {
                    inputType: inputTypes.CHECKBOX,
                    label: "Status Aktif",
                    key: "isActive",
                    // required: true
                },
                {
                    inputType: inputTypes.INPUT,
                    label: "Cron Setting",
                    key: "cron",
                    required: true,
                    helpText:(
                        <a target='_blank' href={"http://www.cronmaker.com"}>
                            ref cron generator
                         </a>
                    )
                    
                },
                {
                    inputType: inputTypes.INPUT,
                    label: "Nama",
                    key: "nama",
                    required: true
                },
                {
                    inputType: inputTypes.INPUT,
                    label: "File Path",
                    key: "pathFile",
                    required: true
                }
            ]}
            onSubmit={async (data, callback) => {
                try {
                    // let dataList = await getDataSceduler({ page: 0, size: 50 })
                    // if( !item && dataList && dataList.data.length > 0){
                    //     callback('Data Setting Sceduler hanya bisa di input 1 saja!!!', true)
                    // }else{
                        let response = item
                            ? await editDataScedulerById(item.id, data)
                            : await addDataSceduler(data)
                        TableWrapper.updateDataItem("mod-table-cron", { id: response.data.id }, response.data)
                        callback(item ? "Data Berhasil Diedit" : "Data Berhasil Ditambah", false)
                    // }
                } catch (error) {
                    callback(error.response.data.detail, true)
                }
            }}
        />
    );
};

export default inject("envStore")(observer(AddSceduler));
