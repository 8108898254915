import { crud, http } from "../../libs/react-mpk/services";
import qs from "query-string";

let service = new crud("/");

export const getDataBupot = (query, fgLapor) => {
  query["fgLapor.equals"] = fgLapor
  return http.get(`/sb`, query);
};

export const getSyncBulk = (data) => {
  return http.get(`/sb/syncBupot/bulk?${qs.stringify(data)}`);
};

export const getSyncBulkByid = (id) => {
  return http.get(`/sb/syncBupot/single/${id}`);
};

export const getFormatReport= (tipeModel,tipeFile,query) => {
  return http.get(`/sb/report/${tipeModel}/${tipeFile}?${qs.stringify(query)}`);
};

export const downloadReport= (filename) => {
  return http.download(`/sb/download/${filename}`);
};

export const getDataBupotById = (id, query) => {
  return http.get(`/sb/${id}`, query);
};

export const addDataBupot = (data) => {
  return http.post(`/sb`, data);
};

export const deleteBupot  = (id, data) => {
  return http.delete(`/sb/${id}`, data);
};

export const updateBupot  = (id, data) => {
  return http.put(`/sb/${id}`, data);
};
export const updateBupotRef  = (id, data) => {
  return http.put(`/sb/${id}/referensi`, data);
};


export default service;