import React, { useEffect, useState } from "react";
import { DataForm, DataTable } from "../../libs/react-mpk/components";
import { TableWrapper } from "../../libs/react-mpk/wrapper";
import jsqr from "jsqr";
import PDFJS from "pdfjs-dist";
import { toast } from "../../libs/react-mpk/services";
import { addDataBupot, deleteBupot } from "./TableBuktiPotong.service";
import {
  addDataBupotSupport,
  deleteBupotSupport,
} from "./TableBuktiPotongSupport.service";
import { inject, observer } from "mobx-react";
PDFJS.GlobalWorkerOptions.workerSrc = require("pdfjs-dist/build/pdf.worker.entry.js");
var jpeg = require("jpeg-js");

const defaultData = () => ({});

const TableBuktiPotongForm = ({
  className = "",
  title = "Form File Scanner",
  visible = false,
  onRequestClose = () => { },
  authStore,
  ...props
}) => {
  let [ready] = useState(true);
  let [arrayPdf, setArrayPdf] = useState([]);
  let [proses, setProses] = useState(false);
  let isSupport = authStore.user.isSupport;

  // useEffect(() => {
  // 	let filterPdf = arrayPdf.filter(d=>(d.isSelect))
  // 	for (let pdfFile of filterPdf) {

  // 		}
  // 		TableWrapper.reload("mod-table-bukti-potong")
  // 		toast.success("Data Berhasil Ditambah")

  // }, [arrayPdf])

  //   const updatePdf = useCallback(
  //     (e, i) => {
  //       setArrayPdf((a) => {
  //         a[i].isSelect = e.target.checked;
  //         console.log(a[i]);
  //         return a;
  //       });
  //     },
  //     [arrayPdf]
  //   );

  const handleDelete = (idBupot) => {
    let newList = arrayPdf.filter((item) => item.data.data.idBupot !== idBupot);
    if (newList.length === 0) {
      setProses(false);
    }
    setArrayPdf(newList);
  };

  var handlePDFs = async (files) => {
    return new Promise(async (resolve, reject) => {
      setProses(true);
      for (let file of files) {
        try {
          let respone = await handlePDF(file.file);
          console.log(
            "🚀 ~ file: TableBuktiPotongForm.js ~ line 65 ~ returnnewPromise ~ respone",
            respone
          );

          if (respone?.length < 1) toast.error("PDF Tidak Valid!");
          // console.log(authStore.user.company.id.toString());
          let resAddBupot;
          if (respone) {
            for (const val of respone) {
              if (isSupport) {
                resAddBupot = await addDataBupotSupport({
                  companyId: authStore.user.company.id.toString(),
                  fileName: val.filename,
                  decodedUrl: val.djpUrl,
                  encodedUrl: val.decodeDjpUrl,
                });
              } else {
                resAddBupot = await addDataBupot({
                  companyId: authStore.user.company.id.toString(),
                  fileName: val.filename,
                  decodedUrl: val.djpUrl,
                  encodedUrl: val.decodeDjpUrl,
                });
              }
              // console.log(resAddBupot);
              respone.isSelect = true;
              respone.data = resAddBupot.data;
              // console.log("🚀 ~ file: TableBuktiPotongForm.js ~ line 87 ~ returnnewPromise ~ respone", respone)
              // console.log(respone.data);
              if (resAddBupot.data !== null) {
                console.log(
                  "🚀 ~ file: TableBuktiPotongForm.js ~ line 90 ~ returnnewPromise ~ respone",
                  respone
                );
                // eslint-disable-next-line no-loop-func
                setArrayPdf((d) => [...d, resAddBupot]);
              } else {
                toast.warning(respone.data.errorMassage);
              }
            }
          }

          setProses(false);
          resolve();
        } catch (error) {
          setProses(false);
          console.log(
            "🚀 ~ file: TableBuktiPotongForm.js ~ line 107 ~ returnnewPromise ~ error",
            error
          );
          // reject(error)
          toast.errorRequest(error);
        }
      }
    });
  };

  var handlePDF = async (file) => {
    return new Promise(async (resolve1) => {
      try {
        var filename = file.name;

        var fileReader = new FileReader();
        fileReader.onload = (e) => {
          // console.log(e.target.result, "e test")
          var typedarray = new Uint8Array(e.target.result);
          // console.log(typedarray);
          PDFJS.getDocument(typedarray).then(function (pdf) {
            console.info("The pdf has ", pdf.numPages, "page(s).");
            // Get PDF each Page
            toast.info("Scan PDF Started");
            var totalPagePDF = pdf.numPages;
            var counter = 0;
            var dpage = [];
            while (counter < totalPagePDF) {
              dpage.push(counter + 1);
              counter++;
            }
            console.log(
              "🚀 ~ file: TableBuktiPotongForm.js ~ line 121 ~ dpage",
              dpage
            );
            document.getElementsByClassName("nothing")[0].innerHTML = "";
            let resp = [];
            var createCanvas = Promise.all(
              dpage.map((i) => {
                return new Promise((resolve, reject) => {
                  pdf.getPage(i).then((page) => {
                    var viewport = page.getViewport(4.0);
                    var canvasId = "canvas" + i;
                    var canvas = document.createElement("canvas");
                    canvas.width = viewport.width;
                    canvas.height = viewport.height;

                    canvas.id = canvasId;
                    document
                      .getElementsByClassName("nothing")[0]
                      .append(canvas);
                    var canvasz = document.getElementById(canvasId);
                    canvasz.width = viewport.width;
                    canvasz.height = viewport.height;
                    page.render({
                      canvasContext: canvasz.getContext("2d"),
                      viewport: viewport,
                    });
                    setTimeout(function () {
                      // Get Image Container QR
                      var canvas = document.getElementById(canvasId);
                      var base64 = canvas.toDataURL("image/jpeg");
                      // Convert base64 to file than Scan QR Code update to requirement data
                      fetch(base64)
                        .then((res) => res.blob())
                        .then((blob) => {
                          try {
                            const fileBlob = new File([blob], filename);
                            var reader = new FileReader();
                            reader.onload = (e) => {
                              var img = jpeg.decode(e.target.result, true);
                              console.log("🚀 ~ file: TableBuktiPotongForm.js ~ line 184 ~ .then ~ img", img)
                              var qr = jsqr(img.data, img.width, img.height);
                              console.log("🚀 ~ file: TableBuktiPotongForm.js ~ line 185 ~ .then ~ qr", qr)
                              if (qr) {
                                var djpUrl = qr.data;
                                var decodeDjpUrl = btoa(djpUrl);
                                resp.push({ djpUrl, decodeDjpUrl, filename });
                              }
                              console.log("🚀 ~ file: TableBuktiPotongForm.js ~ line 190 ~ .then ~ resp", resp)

                              if (i >= dpage.length) {
                                resolve1(resp);
                              }
                            };
                            reader.readAsArrayBuffer(fileBlob);
                          } catch (error) {
                            console.log("🚀 ~ file: TableBuktiPotongForm.js ~ line 199 ~ .then ~ error", error)
                            toast.errorRequest(error);
                            console.log("went wrong");
                          }
                        });
                    }, 1000 * i);
                    console.log(resp);
                    resolve("Done");
                  });
                });
              })
            );
            createCanvas.then((result) => {
              // toast.success('Scan PDF Completed')
            });
          });
        };
        fileReader.readAsArrayBuffer(file);
      } catch (e) {
        toast.errorRequest(e);
        console.log(e);
        console.log("WENT WRONG");
      }
    });
  };

  useEffect(() => {
    window.hahahah = PDFJS;
  }, []);

  return (
    <>
      {ready && (
        <DataForm
          baseId="table-bukti-potong-form"
          title={title}
          visible={visible}
          // data={item}
          onRequestClose={() => {
            setArrayPdf([]);
            onRequestClose();
          }}
          asDialog={true}
          submitLabel="KELUAR"
          showCancel={false}
          loading={proses}
          submitIconClassName=''
          defaultData={props.item || defaultData()}
          definitions={[
            {
              inputType: DataForm.inputTypes.FILE_INPUT,
              label: "File Input",
              multiple: true,
              multiline: true,
              key: "fileInput",
            },
            {
              render: (
                <DataTable
                  baseId="mod-table-review"
                  title="Tabel Review Data"
                  className={className}
                  data={arrayPdf}
                  fit={false}
                  loading={false}
                  columns={[
                    {
                      label: "Fg Status",
                      sortable: true,
                      searchable: true,
                      key: "fgStatus",
                      render: (item) => {
                        switch (item.data.data.fgStatus) {
                          case "0":
                            return "Normal";
                          case "1":
                            return "Pembetulan";
                          case "2":
                            return "Pembatalan";
                          case "3":
                            return "Dihapus";
                          default:
                            return "-";
                        }
                      },
                    },
                    {
                      label: "Fg Lapor",
                      sortable: true,
                      searchable: false,
                      key: "fgLapor",
                      render: (item) => (
                        <TableWrapper.Status
                          type={
                            item.data.data.fgLapor === "Sudah Lapor"
                              ? TableWrapper.Status.types.SUCCESS
                              : TableWrapper.Status.types.ERROR
                          }
                        >
                          {item.fgLapor === "Sudah Lapor"
                            ? "Sudah Lapor"
                            : "Belum Lapor"}
                        </TableWrapper.Status>
                      ),
                    },
                    {
                      label: "Fg Fasilitas",
                      searchable: true,
                      sortable: true,
                      key: "fgFasilitas",
                      render: (item) => {
                        switch (item.data.data.fgFasilitas) {
                          case "0":
                            return "Tanpa Fasilitas";
                          case "1":
                            return "SKB";
                          case "2":
                            return "SKD";
                          case "3":
                            return "DTP";
                          case "4":
                            return "SKT PP 23 2018";
                          case "5":
                            return "Fasilitas Lainnya";
                          default:
                            return "-";
                        }
                      },
                    },
                    {
                      label: "Nomor Bukti Potong",
                      searchable: true,
                      sortable: true,
                      key: "nomerBuktiPotong",
                      render: (item) => item.data.data.noBupot,
                    },
                    {
                      label: "Nomor Revisi",
                      searchable: true,
                      sortable: true,
                      key: "nomerRevisi",
                      render: (item) => item.data.data.revNo,
                    },
                    {
                      label: "Pasal",
                      searchable: true,
                      sortable: true,
                      key: "pasal",
                      render: (item) => item.data.data.jnsPasal,
                    },
                    {
                      label: "FOTF",
                      searchable: true,
                      sortable: true,
                      key: "fotf",
                      render: (item) => item.data.data.fotf,
                    },
                    {
                      label: "Kode Objek Pajak",
                      searchable: true,
                      sortable: true,
                      key: "kdObjPjk",
                      render: (item) => item.data.data.kdObjPjk,
                    },
                    {
                      label: "Jenis Penghasilan",
                      searchable: true,
                      sortable: true,
                      key: "jnsPenghasilan",
                      render: (item) => item.data.data.jnsPenghasilan,
                    },
                    {
                      label: "Bruto",
                      searchable: true,
                      sortable: true,
                      key: "jmlBruto",
                      render: (item) => item.data.data.jmlBruto,
                    },
                    {
                      label: "Tarif",
                      searchable: true,
                      sortable: true,
                      key: "tarif",
                      render: (item) => item.data.data.tarif,
                    },
                    {
                      label: "PPH Dipotong",
                      searchable: true,
                      sortable: true,
                      key: "pphDipotong",
                      render: (item) => item.data.data.pphDipotong,
                    },
                    {
                      label: "Tanggal Pemotongan",
                      searchable: true,
                      sortable: true,
                      key: "tglPemotongan",
                      render: (item) => item.data.data.tglPemotongan,
                    },
                    {
                      label: "Masa Pajak",
                      searchable: true,
                      sortable: true,
                      key: "masaPajak",
                      render: (item) => item.data.data.masaPajak,
                    },
                    {
                      label: "Tahun Pajak",
                      searchable: true,
                      sortable: true,
                      key: "thnPajak",
                      render: (item) => item.data.data.thnPajak,
                    },
                    {
                      label: "Identitas Dipotong",
                      searchable: true,
                      sortable: true,
                      key: "identitasDipotong",
                      render: (item) => item.data.data.identitasDipotong,
                    },
                    {
                      label: "Nomor Dokumen Dasar Pemotongan",
                      searchable: true,
                      sortable: true,
                      key: "nomorDok",
                      render: (item) => (
                        <div>
                          {item.data.data.dokDasarPemotongan.map((b) => (
                            <div key={b.nomerDok} className="mpk-link">
                              {b.nomorDok}
                            </div>
                          ))}
                        </div>
                      ),
                    },
                    {
                      label: "identitas Pemotong Nama Pemotong",
                      searchable: true,
                      sortable: true,
                      key: "nomerDokumen",
                      render: (item) => (
                        <div>
                          {item.data.data.identitasPemotong.namaPemotong}
                        </div>
                      ),
                    },
                    {
                      label: "identitas Pemotong Alamat Pemotong",
                      searchable: true,
                      sortable: true,
                      key: "nomerDokumen",
                      render: (item) => (
                        <div>
                          {item.data.data.identitasPemotong.alamatPemotong}
                        </div>
                      ),
                    },
                    {
                      label: "identitas Pemotong NPWP Pemotong",
                      searchable: true,
                      sortable: true,
                      key: "nomerDokumen",
                      render: (item) => (
                        <div>
                          {item.data.data.identitasPemotong.npwpPemotong}
                        </div>
                      ),
                    },
                  ]}
                  itemActions={[
                    new TableWrapper.action(
                      "Delete",
                      "mdi mdi-delete",
                      (item, index) => {
                        props.modalStore.showConfirm({
                          title: "Delete",
                          children: "yakin?",
                          onSubmit: async (callback, value) => {
                            try {
                              if (isSupport) {
                                await deleteBupotSupport(
                                  item.data.data.idBupot
                                );
                              } else {
                                await deleteBupot(item.data.data.idBupot);
                              }
                              handleDelete(item.data.data.idBupot);
                              callback();
                              toast.success("Data berhasil dihapus");
                            } catch (error) {
                              toast.errorRequest(error);
                              callback(error);
                            }
                          },
                        });
                      },
                      true
                    ),
                  ]}
                />
              ),
            },
            {
              render: (
                <div>
                  <div className="nothing" style={{ display: "none" }}>
                    <canvas className="canvas"></canvas>
                  </div>
                </div>
              ),
            },
          ]}
          onBeforeChange={(key, value) => {
            return value;
          }}
          onChange={async (data, key, value) => {
            if (key === "fileInput") {
              data = await handlePDFs(value);
            }
            return data;
          }}
          onSubmit={async (data, callback) => {
            try {
              // let filterPdf = arrayPdf.filter(d => (d.isSelect))
              // for (let pdfFile of filterPdf) {
              // 	let response = await addDataBupot({
              // 		decodedUrl: pdfFile.djpUrl,
              // 		encodedUrl: pdfFile.decodeDjpUrl
              // 	})
              // }
              TableWrapper.reload("mod-table-bukti-potong");
              callback();
              onRequestClose();
            } catch (error) {
              toast.errorRequest(error);
              callback(error);
            }
          }}
        />
      )}
    </>
  );
};

export default inject(
  "modalStore",
  "authStore"
)(observer(TableBuktiPotongForm));
