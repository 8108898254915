import React from 'react'
import { Router, Switch, Route, Redirect } from "react-router-dom"
import Index from './Index.routes'
import {Login, ForgotPassword, ResetPassword} from '../../modules/Login'
import { inject, observer } from 'mobx-react'

const Product = ({history, navigationStore, authStore}) => {
  
  if(window.location.pathname === '/general') 
    setTimeout(() => navigationStore.redirectTo('/general/index'), 500)
  return (
    <Router history={history}>
      <Switch>
        <Route
          path='/general/login'
          render={props => (
            <Login {...props}/>
          )}
        />
        <Route
          path='/general/forgot'
          render={props => (
            <ForgotPassword {...props}/>
          )}
        />
        <Route
          path='/general/reset_password'
          render={props => (
            <ResetPassword {...props}/>
          )}
        />
        <Route
          path='/general/index'
          render={props => (
            <Index {...props}/>
          )}
        />
      </Switch>
    </Router>
  )
}

export default inject('navigationStore','authStore')(observer(Product))