import React, { useState } from 'react'
import { TableWrapper } from '../../../libs/react-mpk/wrapper'
import t from 'counterpart'
import service, { getDataNpwp, deleteDataNpwp } from './TabelNpwp.service'
import moment from 'moment'
import { inject, observer } from "mobx-react";
import AddUser from "./AddNpwp";
import { toast } from '../../../libs/react-mpk/services'

const TableUser = ({
  className = '',
  showCommandbar = true,
  title,
  ...props
}) => {
  let [showForm, setShowForm] = useState(false);
  let [selectedItem, setSelectedItem] = useState(null)
  return (
    <>
      <TableWrapper
        baseId="mod-table-npwp"
        title="NPWP List"
        className={className}
        // hintMessage={t.translate('modules.table.hint.message')}
        hintIconClassName="mdi mdi-information"
        hintMore={t.translate('modules.table.hint.more')}
        defaultData={[]}
        // defaultSortBy="nama"
        showActionColumn={false}
        useCriteria={true}
        columns={[
          {
            label: 'Npwp',
            sortable: true,
            searchable: true,
            onclick: item =>{
              console.log('klik');
            },
            render: item => item.npwp
          },
          {
            label: 'Nama',
            sortable: true,
            searchable: true,
            onclick: item =>{
              console.log('klik');
            },
            render: item => item.name
          },
          {
            label: 'Alamat',
            sortable: true,
            searchable: true,
            render: item => item.address

          },
          {
            label: 'Kota',
            sortable: true,
            searchable: true,
            render: item => item.city
          },
          {
            label: 'Kode Pos',
            sortable: true,
            searchable: true,
            render: item => item.postalCode
          },
          {
            label: 'No Telepon',
            sortable: true,
            searchable: true,
            render: item => item.phone
          },
          {
            label: 'Email',
            sortable: true,
            searchable: true,
            render: item => item.email,
          },
          {
            label: 'Expired Date',
            sortable: true,
            searchable: true,
            render: item => item.expiredDate,
          },
        ]}
        actions={[
          new TableWrapper.action('Add', 'mdi mdi-plus', () => {
            setShowForm(true)
            setSelectedItem(null)
          }, true)
        ]}
        itemActions={[
          // new TableWrapper.action('Edit', 'mdi mdi-pencil', (item) => {
          //   setShowForm(true)
          //   setSelectedItem(item)
          // }, true),
          // new TableWrapper.action('Delete', 'mdi mdi-delete', (item) => {
          //   props.modalStore.showConfirm({
          //     title: "Delete",
          //     children: "yakin?",
          //     onSubmit: async (callback) => {
          //       try {
          //         await deleteDataNpwp(item.id)
          //         TableWrapper.reload("mod-table-user")
          //         callback()
          //         toast.success("Member berhasil dihapus")
          //       } catch (error) {
          //         toast.errorRequest(error)
          //         callback()
          //       }

          //     }
          //   })
          // },
          // true)
        ]}
        onFetchData={async (query) => {
          return getDataNpwp(query)
        }}
        showCommandbar={showCommandbar}
        showFilterPeriod={false}
      />
      <AddUser
        visible={showForm}
        onRequestClose={() => setShowForm(false)}
        item={selectedItem} />
    </>
  )
}

export default inject("modalStore")(observer(TableUser))
