import { crud, http } from "../../../libs/react-mpk/services";

let service = new crud("/api/admin/proxy");

export const getDataProxy = (query) => {
  return http.get(`/api/admin/proxy`, query);
};
export const addDataProxy = (data) => {
  return http.post(`/api/admin/proxy`, data);
};
export const getDataProxyById = (id) => {
  return http.get(`/api/admin/proxy/${id}`);
};
export const editDataProxyById = (id, data) => {
  return http.put(`/api/admin/proxy/${id}`, data);
};
export const deleteDataProxy= (id, item) => {
  return http.delete(`/api/admin/proxy/${id}`, item);
};
	



export default service;
