import React, { useState } from 'react'
import { Router, Switch, Route } from "react-router-dom"
import { sso } from '../../libs/react-mpk/hocs'
import { PageWrapper } from '../../libs/react-mpk/wrapper'
import Companies from '../../modules/Companies'
import { inject, observer } from "mobx-react";

import {
  BuktiPotong,
  TableLogImport,
  TableLogScan
} from '../../modules/Support'

const Index = ({ history, authStore }) => {

  let isSupport = authStore.user.isSupport
  let support = {
    subname: isSupport ? '- Support' : ''
  }
  return (
    <PageWrapper
      inverseTheme={true}
      appbar={support}
    >
      <Router history={history}>
        <Switch>
          <Route
            path='/product/index/companies'
            render={props => (
              <Companies {...props} />
            )}
          />
          <Route
            path='/product/index/company/details/:tab'
            render={props => (
              <BuktiPotong {...props} />
            )}
          />
          <Route
            path='/product/index/company/logExport'
            render={props => (
              <TableLogImport {...props} />
            )}
          />
          <Route
            path='/product/index/company/logScan'
            render={props => (
              <TableLogScan {...props} />
            )}
          />
        </Switch>
      </Router>
    </PageWrapper>
  )
}

export default sso({
  basePath: '/product/index'
})(inject("authStore")(Index))