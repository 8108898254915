import React, {useState} from 'react'
import { inject, observer } from 'mobx-react'
import { TableWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { getLogExport, getLogExportSupport } from './TableLogImport.service'
import moment from 'moment'
import { useParams } from 'react-router'

const TableLogImport = ({
  className       = '',
  showCommandbar  = true,
  authStore
}) => {
  const [showForm, setShowForm] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)
  const { companyId } = useParams()
  let isSupport = authStore.user.isSupport
  return (
    <>
      <TableWrapper
        baseId="mod-table-log-import"
        title="Tabel Export"
        className={className}
        hintIconClassName="mdi mdi-information"
        hintMore={t.translate('modules.table.hint.more')}
        defaultData={[]}
        defaultSortBy="status"
        defaultCollapse={true}
        useCriteria={true}
        selectable={false}
        showActionColumn={false}
        columns={[
          {
            label: 'File Name',
            searchable: true,
            sortable: true,
            key:'fileName',
            render: item => item.fileName
          },
          {
            label: 'Status',
            searchable: true,
            sortable: true,
            key:'status',
            render: item => (
            <TableWrapper.Status 
              type={item.status=== "Berhasil" 
                ? TableWrapper.Status.types.SUCCESS
                : TableWrapper.Status.types.ERROR
              }
            >
              {item.status==="Berhasil"  ? 'Berhasil' : 'gagal'}
            </TableWrapper.Status>
          )
          },
          {
            label: 'Error Message',
            searchable: true,
            sortable: true,
            key:'errorMessage',
            render: item => item.errorMessage
          }
        ]}
       
        onFetchData={ async query => {
          query["companyId.equals"] = companyId
          if(isSupport){
            return getLogExportSupport(query)
          }else{
            return getLogExport(query)
          }
        }}
        showCommandbar={showCommandbar}
        showFilterPeriod={true}
      />
    </>
  )
}

export default inject('authStore')(observer(TableLogImport))
