import { crud, http } from "../../libs/react-mpk/services";

let service = new crud("/");

export const getLogExport = (query) => {
  return http.get(`/log_export`, query);
};
export const getLogExportSupport = (query) => {
  return http.get(`/support/log_export`, query);
};

export default service;