import React, { useEffect, useState } from 'react'
import { TableWrapper } from '../../../libs/react-mpk/wrapper'
import t from 'counterpart'
import service, { getDataUserNpwpByIdUser, deleteDataUserNpwp, getDataUserNpwp } from './UserNpwp.service'
import { getDataUserById } from '../TabelUser/TabelUser.service'
import moment from 'moment'
import { inject, observer } from "mobx-react";
import AddUserNpwp from "./AddUserNpwp";
import { toast } from '../../../libs/react-mpk/services'
import { useParams } from "react-router-dom";

const TableUser = ({
  className = '',
  showCommandbar = true,
  title,
  ...props
}) => {
  let [showForm, setShowForm] = useState(false);
  let [selectedItem, setSelectedItem] = useState()
  // console.log("🚀 ~ file: UserNpwp.js ~ line 20 ~ selectedItem", selectedItem)
  let [user, setUser] = useState(null)
  let {userId} = useParams()

  useEffect(async()=>{
    try {
      let res = await getDataUserById(userId)
      setUser(res.data)
    } catch (error) {
      console.log("🚀 ~ file: UserNpwp.js ~ line 28 ~ useEffect ~ error", error)
    }
  },[])

  return (
    <>
      <TableWrapper
        baseId="mod-table-npwp-user"
        title={`List NPWP User : ${user ? user.nama:""}`}
        className={className}
        backTo= {'/general/index/user'}
        // hintMessage={t.translate('modules.table.hint.message')}
        hintIconClassName="mdi mdi-information"
        hintMore={t.translate('modules.table.hint.more')}
        defaultData={[]}
        defaultSortBy="npwp"
        useCriteria={true}
        columns={[
          {
            label: 'NPWP',
            key: 'npwp',
            sortable: true,
            searchable: true,
            onclick: item =>{
              console.log('klik');
            },
            render: item => item.npwp.npwp
          },
          {
            label: 'Nama',
            key: 'name',
            sortable: true,
            searchable: true,
            onclick: item =>{
              console.log('klik');
            },
            render: item => item.npwp.name
          },
          {
            label: 'Alamat',
            key: 'address',
            sortable: true,
            searchable: true,
            render: item => item.npwp.address

          },
          {
            label: 'Kota',
            key: 'city',
            sortable: true,
            searchable: true,
            render: item => item.npwp.city
          },
          {
            label: 'Kode Pos',
            key: 'postalCode',
            sortable: true,
            searchable: true,
            render: item => item.npwp.postalCode
          },
          {
            label: 'No Telepon',
            key: 'phone',
            sortable: true,
            searchable: true,
            render: item => item.npwp.phone
          },
          {
            label: 'Email',
            key: 'email',
            sortable: true,
            searchable: true,
            render: item => item.npwp.email,
          },
          {
            label: 'Expired Date',
            key: 'expiredDate',
            sortable: true,
            // searchable: true,
            onclick: item =>{
              console.log('klik');
            },
            render: item => item.npwp.expiredDate
          },
        ]}
        actions={[
          new TableWrapper.action('Add', 'mdi mdi-plus', () => {
            setShowForm(true)
            setSelectedItem(null)
          }, true)
        ]}
        itemActions={[
          // new TableWrapper.action('Edit', 'mdi mdi-pencil', (item) => {
          //   setShowForm(true)
          //   setSelectedItem(item)
          // }, true),
          new TableWrapper.action('Delete', 'mdi mdi-delete', (item) => {
            props.modalStore.showConfirm({
              title: "Delete",
              children: "yakin?",
              onSubmit: async (callback) => {
                try {
                  await deleteDataUserNpwp(item.id)
                  TableWrapper.reload("mod-table-npwp-user")
                  callback()
                  toast.success("NPWP User berhasil dihapus")
                } catch (error) {
                  toast.errorRequest(error)
                  callback()
                }

              }
            })
          },
            true)
        ]}
        onFetchData={async (query) => {
          return getDataUserNpwpByIdUser(userId, query)
        }}
        showCommandbar={showCommandbar}
        showFilterPeriod={false}
      />
      <AddUserNpwp
        visible={showForm}
        onRequestClose={() => setShowForm(false)}
        item={selectedItem}
        user={user} />
    </>
  )
}

export default inject("modalStore")(observer(TableUser))
