import React, {useState} from 'react'
import { TableWrapper } from '../../../libs/react-mpk/wrapper'
import t from 'counterpart'
import {getLogScan} from './TableLogScan.service'
import moment from 'moment'

const TableLogScan = ({
  className       = '',
  showCommandbar  = true
}) => {
  const [showForm, setShowForm] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)
  
  return (
    <>
      <TableWrapper
        baseId="mod-table-log-scan"
        title="Tabel Log Scan"
        className={className}
        hintIconClassName="mdi mdi-information"
        hintMore={t.translate('modules.table.hint.more')}
        defaultData={[]}
        defaultSortBy="createdDate"
        defaultCollapse={true}
        useCriteria={true}
        selectable={false}
        showActionColumn={false}
        style={{flex:1}}
        columns={[
          {
            label: 'URL decode',
            searchable: true,
            sortable: true,
            key:'urlDecode',
            render: item => (
         
              <div style={{maxWidth:300, whiteSpace:'break-spaces', wordBreak:'break-all'}}>
                {item.urlDecode}
              </div>
          )
          }, 
          {
            label: 'File Name',
            searchable: true,
            sortable: true,
            key:'fileName',
            render: item => item.fileName
          },
          {
            label: 'Status',
            searchable: true,
            sortable: true,
            key:'status',
            render: item => (
              <TableWrapper.Status
              type={item.status=== "Berhasil"  
                ? TableWrapper.Status.types.SUCCESS
                : TableWrapper.Status.types.ERROR
              }
            >
              {item.status=== "Berhasil" ? 'Berhasil' : 'gagal'}
            </TableWrapper.Status>
            )
          },{
            label: 'Error Message',
            searchable: true,
            sortable: true,
            key:'errorMessage',
            render: item => item.errorMessage
          },
          {
            label: 'Tipe Log',
            searchable: true,
            sortable: true,
            key:'tipe',
            render: item => item.tipe
          },
          {
            label: 'Tanggal Log',
            searchable: true,
            sortable: true,
            definition:{
              inputType : TableWrapper.inputTypes.DATEPICKER,
              criterias: ['greaterThan', 'lessThan']
            },
            key:'createdDate',
            render: item => moment(item.createdDate).format('DD-MM-YYYY')
          },
        ]}
        onFetchData={ async query => {
          if (query["createdDate.greaterThan"]) {
            query["createdDate.greaterThan"] = moment(query["createdDate.greaterThan"]).toISOString()
          }
          if ( query["createdDate.lessThan"]) {
             query["createdDate.lessThan"] = moment(query["createdDate.lessThan"]).toISOString()
          }
          console.log(query);
          return getLogScan(query)
        }}
        showCommandbar={showCommandbar}
        showFilterPeriod={true}
      />
    </>
  )
}

export default TableLogScan
