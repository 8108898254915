import React from 'react'
import { TabsWrapper } from '../../../libs/react-mpk/wrapper'
import { inject, observer } from 'mobx-react'
import TableBuktiPotong from '../TableBuktiPotong'


const BuktiPotong = ({ 
  className         = '',
  navigationStore, 
  envStore,
}) => {
  return (

    <TabsWrapper
      title="Bukti Potong"
      baseId="mod-details-sample"
      className={className}
      tabs={[
        {
          label: 'All',
          key: 'all',
          render: (
            <TableBuktiPotong 
              showCommandbar={false}
            />
          )
        },
        {
          label: 'Belum Lapor',
          key: 'belumLapor',
          render: (
            <TableBuktiPotong 
              showCommandbar={false}
              fgLapor="Belum Lapor"
            />
          )
        },
        {
          label: 'Sudah Lapor',
          key: 'sudahLapor',
          render: (
            <TableBuktiPotong 
              showCommandbar={false}
              fgLapor="Sudah Lapor"
            />
          )
        },
      ]}
      onChange={(tab, callback) => {
        navigationStore.redirectTo(`/${envStore.env.applicationType}/index/company/details/${tab.key}`)
        callback()
      }}
    />
  )
}

export default inject('navigationStore', 'envStore')(observer(BuktiPotong))
