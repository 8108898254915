import React, { useState, useEffect } from 'react'
import { TableWrapper } from '../../../libs/react-mpk/wrapper'
import t from 'counterpart'
import service, { getDataSceduler, deleteDataSceduler } from './TabelSceduler.service'
import moment from 'moment'
import { inject, observer } from "mobx-react";
import AddSceduler from "./AddSceduler";
import { toast } from '../../../libs/react-mpk/services'

const TableSceduler = ({
  className = '',
  showCommandbar = true,
  title,
  ...props
}) => {
  let [showForm, setShowForm] = useState(false);
  let [selectedItem, setSelectedItem] = useState(null)
  let [cronlist, setScedulerList] = useState([])

  useEffect(async() => {
    try {
      const dataList = await getDataSceduler({ page: 0, size: 50 })
      if(dataList){
        setScedulerList(dataList.data)
      }
    } catch (error) {
    console.log("🚀 ~ file: TabelSceduler.js ~ line 27 ~ useEffect ~ error", error)
    }
}, [])

  return (
    <>
      <TableWrapper
        baseId="mod-table-cron"
        title="Sceduler List"
        className={className}
        // hintMessage={t.translate('modules.table.hint.message')}
        hintIconClassName="mdi mdi-information"
        hintMore={t.translate('modules.table.hint.more')}
        defaultData={[]}
        useFilter={false}
        defaultSortBy="createdDate"
        useCriteria={true}
        columns={[
          {
            label: 'Aktif',
            sortable: true,
            searchable: true,
            // render: item => (

            // ),
            render: item => (
              <TableWrapper.Status
              type={item.isActive  
                ? TableWrapper.Status.types.SUCCESS
                : TableWrapper.Status.types.ERROR
              }
            >
              {item.isActive ? 'Aktif' : 'Nonaktif'}
            </TableWrapper.Status>
            )
          },
          
          {
            label: 'Nama',
            sortable: true,
            searchable: true,
            render: item => item.nama
          },
          {
            label: 'Cron Setting',
            sortable: true,
            searchable: true,
            render: item => item.cron

          },
          {
            label: 'File Path',
            sortable: true,
            searchable: true,
            render: item => item.pathFile
          }
        ]}
        actions={[
          new TableWrapper.action('Add', 'mdi mdi-plus', () => {
            if(cronlist.lenght > 0 ){
              toast.warning("Data Setting Sceduler hanya bisa di input 1 saja!!!")
            }else{
              setSelectedItem(null)
              setShowForm(true)
            }
              
          }, true)
        ]}
        itemActions={[
          new TableWrapper.action('Edit', 'mdi mdi-pencil', (item) => {
            setShowForm(true)
            setSelectedItem(item)
          }, true),
          new TableWrapper.action('Delete', 'mdi mdi-delete', (item) => {
            props.modalStore.showConfirm({
              title: "Delete",
              children: "yakin?",
              onSubmit: async (callback) => {
                try {
                  await deleteDataSceduler(item.id)
                  TableWrapper.reload("mod-table-cron")
                  callback()
                  toast.success("Member berhasil dihapus")
                } catch (error) {
                  toast.errorRequest(error)
                  callback()
                }

              }
            })
          },
            true)
        ]}
        onFetchData={async (query) => {
          return getDataSceduler(query)
        }}
        showCommandbar={showCommandbar}
        showFilterPeriod={false}
      />
      <AddSceduler
        visible={showForm}
        onRequestClose={() => setShowForm(false)}
        item={selectedItem} />
    </>
  )
}

export default inject("modalStore")(observer(TableSceduler))
