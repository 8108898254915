import React, { useState, useEffect, Children } from "react";
import { DataForm } from "../../../libs/react-mpk/components";
import { TableWrapper } from "../../../libs/react-mpk/wrapper"
import { toast } from "../../../libs/react-mpk/services"
import { inject, observer } from "mobx-react";
import service, { addDataUser, editDataUserById, editDataUserByIdUser } from "./TabelUser.service";
import { getDataRole } from "../TabelRoles/TabelRole.service";
import { find } from "lodash";
import { options } from "superagent";

const { inputTypes } = DataForm;
const AddUser = ({ visible = true, ...props }) => {
    let item = props.authStore.user
    let isSupport = props.authStore.user.isSupport
    const [ready, setReady] = useState(false)
    const [roleList, setRoleList] = useState([])
    useEffect(async() => {
        //   const dataRole = await getDataRole({ page: 0, size: 50 })
        //   setRoleList(dataRole.data)
        if (visible)
            setReady(true)
    }, [visible])
    return ready && (
        <DataForm
            baseId="profile-detail"
            title="Profile"
            asDialog={false}
            defaultData={
                item ?
                {...item, password: ''} : {
                    nama: "",
                    username: "",
                    role: "",
                    password: "",
                    konfirmasi_password: "",
                    isActive: false,
                }
            }
            definitions={[
                {
                    inputType: inputTypes.CHECKBOX,
                    label: 'IsAktive',
                    key: "isActive",
                    disabled: true,
                },
                {
                    inputType: inputTypes.INPUT,
                    label: "Nama",
                    key: "nama",
                    required: true
                },
                {
                    inputType: inputTypes.INPUT,
                    label: "Username",
                    key: "username",
                    disabled: true,
                    maxLength: 25,
                    minLength: 3,
                    required: true,
                },
                {
                    inputType: inputTypes.INPUT,
                    label: "Email",
                    key: "email",
                    validation: 'email',
                    required: true,
                },
                {
                    inputType: inputTypes.INPUT,
                    label: 'Role',
                    key: "role",
                    disabled: true,
                    required: true,
                },
                {
                    inputType: inputTypes.INPUT,
                    label: 'Change Password',
                    className: 'mpk-padding-N padding-right',
                    key: "password",
                    minLength: 8,
                    type: 'password',
                    width: '50%',
                    // required: true,
                    // show: item?false:true
                },
                {
                    inputType: inputTypes.INPUT,
                    label: 'Konfirmasi Password',
                    key: "konfirmasi_password",
                    type: 'password',
                    width: '50%',
                    // required: true,
                    // show: item?false:true
                }
            ]}
            onChange={(data, key, value)=>{
                if(key === 'username'){
                    data.username = value.toLowerCase()
                }
                return data
            }}
            onSubmit={async (data, callback) => {
                if(!data.username.match(/^[0-9a-z]+$/)){
                    callback('username tidak boleh ada simbol atau space', true, false)
                }else if(data.password && data.password !== data.konfirmasi_password){
                    callback('Konfirmasi Password tidak sama dengan password', true, false)
                }else{
                    let data_update = {...data}
                    if(!data.password){
                        data.password = null
                        delete data.konfirmasi_password
                    }
                    delete data.menu
                    delete data.name
                    delete data.userNpwp
                    try {
                        let res
                        if(isSupport){
                            res = await editDataUserById(item.id, data)
                        }else{
                            res = await editDataUserByIdUser(item.id, data)
                        }
                        // console.log("🚀 ~ file: Profile.js ~ line 122 ~ onSubmit={ ~ res", res.data)
                        // console.log("🚀 ~ file: Profile.js ~ line 123 ~ onSubmit={ ~ data_update", data_update)
                        // console.log("🚀 ~ file: Profile.js ~ line 126 ~ onSubmit={ ~ data_update", data_update)
                        data_update.name = data.nama
                        props.authStore.setProfile(data_update);
                        callback(item ? "Data Berhasil Diedit" : "Data Berhasil Ditambah", false)
                    } catch (error) {
                        callback(error.response.data.detail, true)
                    }
                }
            }}
        />
    );
};

export default inject("envStore","authStore")(observer(AddUser));
