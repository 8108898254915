import { crud, http } from "../../libs/react-mpk/services";
import qs from "query-string";

let service = new crud("/");
export const LoginService = (data) => {
  return http.post(`/auth/authenticate`, data, {withCredentials : true});
};
export const GetSN = () => {
  return http.get(`/auth/get_sn`, {}, {withCredentials : true});
};
export const ForgotService = (data) => {
  return http.post(`/auth/forgot_password?email=${data.email}`, {}, {withCredentials : true});
};
export const ResetService = (data) => {
  return http.put(`/auth/reset_password?token=${data.token}&password=${data.password}`, {}, {withCredentials : true});
};
export const LogoutService = (data) => {
  return http.delete(`/auth/revoke`, data, {withCredentials : true});
};

export const getUploadAggrement = () => {
  return http.get(`/static/aggreement.html`, {}, {}, {useDefaultBaseUrl: false, useDefaultHost: false})
}

export default service;