import React, {useState} from 'react'
import { inject, observer } from 'mobx-react'
import { TableWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import {getLogScan, getLogScanSupport, getLogScanExport} from './TableLogScan.service'
import moment from 'moment'
import { useParams } from 'react-router'
import { toast } from '../../libs/react-mpk/services'

let baseId ='mod-table-log-scan'
const TableLogScan = ({
  className       = '',
  showCommandbar  = true,
  authStore
}) => {
  const [showForm, setShowForm] = useState(false)
  const [loadingExp, setLoadingExp] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)
  const { companyId } = useParams()
  let isSupport = authStore.user.isSupport
  return (
    <>
      <TableWrapper
        baseId={baseId}
        title="Tabel Log Scan"
        className={className}
        hintIconClassName="mdi mdi-information"
        hintMore={t.translate('modules.table.hint.more')}
        defaultData={[]}
        defaultSortBy="createdDate"
        defaultCollapse={true}
        useCriteria={true}
        selectable={true}
        showActionColumn={false}
        showCommandbar={true}
        style={{flex:1}}
        columns={[
          // {
          //   label: 'URL decode',
          //   searchable: true,
          //   sortable: true,
          //   key:'urlDecode',
          //   render: item => (
         
          //     <div style={{maxWidth:300, whiteSpace:'break-spaces', wordBreak:'break-all'}}>
          //       {item.urlDecode}
          //     </div>
          // )
          // }, 
          {
            label: 'File Name',
            searchable: true,
            sortable: true,
            key:'fileName',
            render: item => item.fileName
          },
          {
            label: 'Status',
            searchable: true,
            sortable: true,
            key:'status',
            render: item => (
              <TableWrapper.Status
              type={item.status=== "Berhasil"  
                ? TableWrapper.Status.types.SUCCESS
                : TableWrapper.Status.types.ERROR
              }
            >
              {item.status=== "Berhasil" ? 'Berhasil' : 'gagal'}
            </TableWrapper.Status>
            )
          },{
            label: 'Error Message',
            searchable: true,
            sortable: true,
            key:'errorMessage',
            render: item => item.errorMessage
          },
          {
            label: 'Tipe Log',
            searchable: true,
            sortable: true,
            key:'tipe',
            render: item => item.tipe
          },
          {
            label: 'Tanggal Log',
            searchable: true,
            sortable: true,
            definition:{
              inputType : TableWrapper.inputTypes.DATEPICKER,
              criterias: ['greaterThan', 'lessThan']
            },
            key:'createdDate',
            render: item => moment(item.createdDate).format('DD-MM-YYYY')
          },
        ]}
        actions={[
          new TableWrapper.action(
            'Export',
            'mdi mdi-export',
            () => {
              TableWrapper.getData(
								`${baseId}`, async (e)=>{
									try {
										let index_selected = await JSON.parse(TableWrapper.getSelected(`${baseId}`))
										let selected = []
										for(let x of index_selected){
                      selected.push(e[x].id);
										}
										if(selected.length < 1){
                      throw 'Tidak ada data yang dipilih'
										}
										// let data_ = {
										// 	listId : JSON.stringify(selected)
										// }
										if(!loadingExp){
											setLoadingExp(true)
                      let q = {};
                      q['id.in'] = selected.join(',');
											let res = await getLogScanExport(q)
											// await chatHistory.export_bulk(data_)
											toast.success('Berhasil Export Log Scan')
											setTimeout(() => setLoadingExp(false), 2000)
										}
									} catch (error) {
										if (error === 'Tidak ada data yang dipilih'){
											toast.error(error)
										}else{
											toast.errorRequest(error)
										}
									}
								}
							)
            },
            true
          ),
        ]}
        onFetchData={ async query => {
          if (query["createdDate.greaterThan"]) {
            query["createdDate.greaterThan"] = moment(query["createdDate.greaterThan"]).toISOString()
          }
          if ( query["createdDate.lessThan"]) {
             query["createdDate.lessThan"] = moment(query["createdDate.lessThan"]).toISOString()
          }
          query["companyId.equals"] = companyId
          if(isSupport){
            return getLogScanSupport(query)
          }else{
            return getLogScan(query)
          }
        }}
        showFilterPeriod={true}
      />
    </>
  )
}

export default inject('authStore')(observer(TableLogScan))

