import React, { useState, useEffect } from "react";
import { DataForm } from "../../libs/react-mpk/components";
import moment from "moment";
import { inject, observer } from "mobx-react";
import { getFormatReport } from "./TableBuktiPotong.service";
import { getFormatReportSupport } from "./TableBuktiPotongSupport.service";
import { TableWrapper } from "../../libs/react-mpk/wrapper";
import { toast } from "../../libs/react-mpk/services";
import { Label } from "react-md";

const defaultData = (authStore) => ({
  tipeFile: "excel",
  tipeModel: "model1",
  thnPajak: "2019",
  fgStatus: "-",
  fgLapor: "-",
  fotf: "Tidak Final",
  identitasDipotong:
    authStore.user.company.subscriptions[0].organizations[0].npwp,
});

const TableBuktiPotongFormExport = ({
  visible = false,
  onRequestClose = () => { },
  authStore,
  envStore,
  ...props
}) => {
  let [item, setItem] = useState(null);
  let [ready, setReady] = useState(true);
  let [disfotf, setDisFotf] = useState(true);
  let [distipeFile, setDisTypeFile] = useState(false);
  const [years, setYears] = useState([]);

  let isSupport = authStore.user.isSupport
  const [defData, setDefData] = useState({
    tipeFile: "excel",
    tipeModel: "model1",
    thnPajak: "2019",
    fgStatus: "-",
    fgLapor: "-",
    fotf: "Tidak Final",
    identitasDipotong:
      authStore.user.company.subscriptions[0].organizations[0].npwp,
    createdDateGt:'',
    createdDateLt:'',
    nomorDok:'',
    createdBy:'',
  })
  const generateYears = () => {
    let currentYear = moment(envStore.env.timestamp).year();
    for (let y = currentYear; y >= 2019; y--) {
      years.push(String(y));
    }
    setYears(years);
  };

  useEffect(() => {
    generateYears();
  }, []);

  return (
    ready && (
      <DataForm
        baseId="table-export-form"
        title="Form Export"
        visible={visible}
        // data={item}
        onRequestClose={onRequestClose}
        asDialog={true}
        defaultData={defData}
        definitions={[
          {
            inputType: DataForm.inputTypes.SELECT,
            label: "Tipe Model",
            key: "tipeModel",
            value: defData.tipeModel,
            options: [
              { label: "Data Espt 1771", value: "model1" },
              { label: "Summary", value: "model2" },
              { label: "Data E-Form 1771", value: "model3" },
            ],
          },
          {
            inputType: DataForm.inputTypes.SELECT,
            label: "Tipe File",
            key: "tipeFile",
            value: defData.tipeFile,
            disabled: distipeFile,
            options: [
              { label: "Excel", value: "excel" },
              { label: "CSV", value: "csv" },
            ],
          },
          {
            inputType: DataForm.inputTypes.SELECT,
            label: "By Tahun Pajak",
            key: "thnPajak",
            value: defData.thnPajak,
            options: years,
          },
          {
            inputType: DataForm.inputTypes.SELECT,
            label: "Fg Status",
            key: "fgStatus",
            value: defData.fgStatus,
            options: [
              { label: "Semua", value: "-" },
              { label: "Normal", value: "Normal" },
              { label: "Pembetulan", value: "Pembetulan" },
              { label: "Pembatalan", value: "Pembatalan" },
              { label: "Hapus", value: "Hapus" },
            ],
          },
          {
            inputType: DataForm.inputTypes.SELECT,
            label: "Fg Lapor",
            key: "fgLapor",
            value: defData.fgLapor,
            options: [
              { label: "Semua", value: "-" },
              { label: "Sudah Lapor", value: "Sudah Lapor" },
              { label: "Belum Lapor", value: "Belum Lapor" },
            ],
          },
          {
            inputType: DataForm.inputTypes.SELECT,
            label: "FOTF",
            key: "fotf",
            value: defData.fotf,
            disabled: disfotf,
            options: [
              { label: "Semua", value: "-" },
              { label: "Final", value: "Final" },
              { label: "Tidak Final", value: "Tidak Final" },
            ],
          },
          {
            inputType: DataForm.inputTypes.SELECT,
            label: "Identitas Dipotong",
            key: "identitasDipotong",
            value: defData.identitasDipotong,
            options: authStore.user.company.subscriptions[0].organizations.map(
              (b) => ({
                label: b.npwp,
                value: b.npwp,
              })
            ),
          },
          {
            render:(
              <div style={{width:"100%", marginBottom:20}}>
                <Label width='100%'>Tanggal Dibuat :</Label>
              </div>
            ),
            width:"100%",
            show:defData.tipeModel === 'model2',
          },
          {
            inputType: DataForm.inputTypes.DATE,
            label: "Dari Tanggal",
            key: "createdDateGt",
            value: defData.createdDateGt,
            width:"48%",
            className:"mpk-margin-N margin-right",
            show:defData.tipeModel === 'model2',

          },
          {
            inputType: DataForm.inputTypes.DATE,
            label: "Sampai Tanggal",
            key: "createdDateLt",
            value: defData.createdDateLt,
            width:"48%",
            show:defData.tipeModel === 'model2',
          },
          {
            inputType: DataForm.inputTypes.INPUT,
            label: "No Dokumen Dasar Pemotong",
            key: "nomorDok",
            value: defData.nomorDok,
            show:defData.tipeModel === 'model2',
          },
          {
            inputType: DataForm.inputTypes.INPUT,
            label: "Username Pembuat",
            key: "createdBy",
            value: defData.createdBy,
            show:defData.tipeModel === 'model2',
          },
        ]}
        onChange={(data, key, value) => {
          if (key === 'tipeModel') {

            switch (value) {
              case 'model1':
                setDisFotf(true)
                setDisTypeFile(false)
                data.fotf = 'Tidak Final'
                break;
              case 'model2':
                setDisFotf(false)
                setDisTypeFile(true)
                data.fotf = '-'
                data.tipeFile = 'excel'
                break;
              case 'model3':
                setDisFotf(true)
                setDisTypeFile(false)
                data.fotf = 'Tidak Final'
                break;
              default:
                setDisFotf(true)
                setDisTypeFile(false)
                data.fotf = '-'
                break;
            }
          }
          setDefData(data)
          return data;
        }}
        onSubmit={async (data, callback) => {
          try {
            if (
              authStore.user.company.role.name === "ADMIN COMPANY" ||
              authStore.user.company.role.name === "SUB ADMIN COMPANY"
            ) {
              let query = {
                "thnPajak.equals": data.thnPajak,
                "identitasDipotong.equals": data.identitasDipotong,
                "flagFasilitas.notEquals": "DTP",
                "companyId.equals": authStore.user.company.id.toString()
              };
              if (data.tipeModel === "model2") {
                delete query["flagFasilitas.notEquals"];
              }
              if (data.fgStatus === "-") {
                delete data.fgStatus;
              } else {
                query["fgStatus.equals"] = data.fgStatus;
              }
              if (data.fgLapor === "-") {
                delete data.fgLapor;
              } else {
                query["fgLapor.equals"] = data.fgLapor;
              }
              if (data.fotf === "-") {
                delete data.fotf;
              } else {
                query["fotf.equals"] = data.fotf;
              }
              if(data.createdDateGt){
                query["createdDate.greaterThan"] = data.createdDateGt+'T00:00:00.Z';
                
              }
              if(data.createdDateLt){
                query["createdDate.lessThan"] = data.createdDateLt+'T23:59:59.Z';
                
              }
              if(data.nomorDok){
                query["nomorDok.contains"] = data.nomorDok;
              }
              if(data.createdBy){
                query["createdBy.equals"] = data.createdBy;
                
              }
              if (isSupport) {
                await getFormatReportSupport(
                  data.tipeModel,
                  data.tipeFile,
                  query
                );
              } else {
                await getFormatReport(
                  data.tipeModel,
                  data.tipeFile,
                  query
                );

              }
              
            } else {
              let query = {
                "thnPajak.equals": data.thnPajak,
                "identitasDipotong.equals": data.identitasDipotong,
                "flagFasilitas.notEquals": "DTP",
                // "createdBy.contains": authStore.user.username,
                "companyId.equals": authStore.user.company.id.toString()
              };
              if (data.tipeModel === "model2") {
                delete query["flagFasilitas.notEquals"];
              }
              if (data.fgStatus === "-") {
                delete data.fgStatus;
              } else {
                query["fgStatus.equals"] = data.fgStatus;
              }
              if (data.fgLapor === "-") {
                delete data.fgLapor;
              } else {
                query["fgLapor.equals"] = data.fgLapor;
              }
              if (data.fotf === "-") {
                delete data.fotf;
              } else {
                query["fotf.equals"] = data.fotf;
              }
              if(data.createdDateGt){
                query["createdDate.greaterThan"] = data.createdDateGt+'T00:00:00.Z';
                
              }
              if(data.createdDateLt){
                query["createdDate.lessThan"] = data.createdDateLt+'T23:59:59.Z';
                
              }
              if(data.nomorDok){
                query["nomorDok.contains"] = data.nomorDok;
                
              }
              if(data.createdBy){
                query["createdBy.equals"] = data.createdBy;
                
              }
              if (isSupport) {
                delete query['createdBy.contains']
                await getFormatReportSupport(
                  data.tipeModel,
                  data.tipeFile,
                  query
                );
              } else {
                await getFormatReport(
                  data.tipeModel,
                  data.tipeFile,
                  query
                );

              }
            }

            // TableWrapper.reload("mod-table-bukti-potong");
            callback("Data berhasil diexport", false);
          } catch (error) {
            console.log("🚀 ~ file: TableBuktiPotongFormExport.js ~ line 258 ~ onSubmit={ ~ error", error)
            toast.errorRequest(error);
            callback(error);
          }
        }}
        submitLabel="Download"
      />
    )
  );
};

export default inject(
  "authStore",
  "envStore"
)(observer(TableBuktiPotongFormExport));
